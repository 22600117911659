@import '../../../utils/stylesheet/mixins';
@import '../../../utils/stylesheet/colors';

.componentWrapper {
  @include flex-center(row);
  position: relative;
  z-index: 1;
  .card {
    @include flex-center(row);
    width: 368px;
    height: 258px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 8px 16px rgba(146, 119, 255, 0.5);
    border-radius: 16px;
    padding: 2rem;
    @include media-query('phone-wide', max) {
      width: 322px;
      height: 225px;
    }

    @include media-query('desktop-wide', max) {
      width: 300px;
      height: 200px;
    }

    @include media-query('tablet-wide', max) {
      width: 257px;
      height: 175px;
    }

    .question {
      @include font-poppins(20, $vc_grey);
      text-align: left;
      @include media-query('phone-wide', max) {
        @include font-poppins(17.5, $vc_grey, 400, 32);
      }
    }
    .bars {
      @include flex-center(column);
      gap: 0.75rem;
      .bar {
        height: 24px;
        width: 319px;
        border-radius: 24px;
        background: $vc_light_grey;
        @include media-query('tablet-wide', max) {
          height: 16px;
          width: 230px;
        }
      }
    }
  }
  .cardStack {
    position: absolute;
    z-index: -1;
    transform: rotate(-6.21deg);
  }
  .swapButton {
    position: absolute;
    bottom: -1.25rem;
    cursor: pointer;
    :active {
      transform: translateY(-1px);
    }
    .button {
      background-color: $light_voilet;
      padding: 8px 16px;
      border-radius: 40px;
      @include media-query('phone-wide', max) {
        padding: 7px 14px;
      }
    }
  }
}
