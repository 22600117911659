@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.tooltipData {
  padding: 1rem 1rem 0 1rem;
  letter-spacing: 0.75px;
  .header {
    @include font-poppins(16, #fcfcfc, 600, 28, normal);
  }
  ul {
    list-style: none;
    padding-inline-start: 0;
  }
  .subtext {
    @include font-poppins(14, #fcfcfc, normal, 21, normal);
  }
  li {
    @include font-poppins(14, rgba(252, 252, 252, 0.8), normal, 21, normal);
    margin: 0.25rem 0;
  }
}

.pageHeader {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: $trivia_section_bg;
  @include flex(row, flex-start, center);
  padding: 0.75rem 2rem 0.75rem 0;
  .titleWrapper {
    width: 100%;
    height: 40px;
    padding: 0 3rem;
    @include flex(row, space-between, center);
    .header {
      @include font-poppins(28, $light_purple, 600, 42, normal);
    }

    .triviaLogo {
      display: none;
    }

    .companyWrapper {
      @include flex(row, flex-start, center);
      letter-spacing: 0.75px;
      .company {
        @include font-poppins(14, #ffffff, 500, 21, normal);
      }
      .planInfo {
        margin: 0 0.75rem;
        @include font-poppins(12, #fefeff, normal, 18, normal);
        strong {
          line-height: 21px;
          font-weight: 600;
        }
      }
      a {
        text-decoration: none;
      }
    }

    .userWrapper {
      @include flex(row, flex-start, center);
      .actions {
        @include flex(row, space-evenly, center);
        div {
          background: rgba(146, 119, 255, 0.1);
          height: 36px;
          width: 26px;
          @include flex(row, center, center);
          margin-right: 1rem;
          border-radius: 4px;
          cursor: pointer;
          padding: 0 0.5rem;
          &:last-child {
            margin-right: 1.5rem;
          }
          a {
            padding: 0.25rem 0.5rem;
            width: 100%;
            display: flex;
            justify-content: center;
          }
        }
        border-right: 0.5px solid rgba(146, 119, 255, 0.4);
      }

      .user {
        @include font-poppins(14, #fff, 500, 21, normal);
        @include flex(row, flex-start, center);
        padding-left: 1rem;
        position: relative;
        width: 100%;
        .logButton {
          border: none !important;
          color: #fff !important;
          font-weight: 500 !important;
        }

        .menuButton {
          border: none !important;
          color: white !important;
          font: bolder;
          .avatar {
            margin-right: 1rem;
          }
        }
        .MenuDropdown {
          margin-top: 1rem !important;
          margin-left: 2rem;
          @include font-poppins(28, $light_purple, 600, 42, normal);
        }

        .avatar {
          border-radius: 50%;
          height: 40px;
          width: 40px;
        }

        .arrow {
          content: '';
          border: solid #fff;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 3px;
          transition: all 0.2s ease-in;
          margin-left: 0.8rem;
          margin-bottom: 0.2rem;
        }

        .down {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }

        .up {
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
        }
      }
    }

    @include media-query('tablet-wide', max) {
      display: none;
    }
  }
  .pageHeader {
    display: none;
    padding: 0;
    position: relative;
    @include media-query('tablet-wide', max) {
      @include flex(row, space-between, center);
      width: max-content;
      .text {
        @include font-montserrat(14, $lilac, 600, 17, normal);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        margin-left: 2rem;
      }
    }
  }
  .menu {
    .menuIcon {
      cursor: pointer;
      display: inline-block;
      padding: 1.75rem 1.25rem;
      position: relative;
      user-select: none;

      .icon {
        background: $white;
        display: block;
        height: 2px;
        position: relative;
        transition: background 0.2s ease-out;
        width: 1.3rem;
      }

      .icon:before,
      .icon:after {
        background: $white;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        transition: all 0.2s ease-out;
        width: 100%;
      }

      .icon:before {
        top: 6px;
      }

      .icon:after {
        top: -6px;
      }
    }

    .menuBtn {
      display: none;
    }
  }

  @include media-query('tablet-wide') {
    .menu .menuIcon {
      display: none;
    }
  }

  @include media-query('tablet', max) {
    padding-left: 0.5rem;

    .titleWrapper {
      margin-left: 0;
      padding: 0 0.5rem;
    }
    .titleWrapper .header {
      @include font-poppins(16, $light_purple, 600, 20, normal);
    }

    .titleWrapper .company {
      display: none;
    }
  }

  @include media-query('tablet-wide', max) {
    padding-bottom: 0.65rem;
  }
}

.avatarWrapper {
  .avatar {
    border-radius: 50%; 
    height: 40px;
    width: 40px;
  }

  .arrow {
    content: '';
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transition: all 0.2s ease-in;
    margin-left: 0.8rem;
    margin-bottom: 0.2rem;
  }
  .purple {
    border-color: $purple;
  }
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
}

.public {
  @include media-query('tablet-wide', max) {
    padding-bottom: 2rem;
    .titleWrapper {
      .header {
        margin: auto;
      }
    }
  }
}

.appBar {
  position: relative;
  background: $grey;
  color: $white;
  border-bottom: 1px solid $dark_blue;
  box-shadow: 1px 4px 2px -2px rgba(0, 0, 0, 0.2);

  .toolBar {
    background-color: #2e3452;
    .closeMenuIcon:hover {
      cursor: pointer;
    }
  }
}

.hamburgerMenuWrapper {
  background: $grey;
  width: 100%;
  height: 100%;

  .userInfo {
    @include flex(row, flex-start, center);
    img,
    svg {
      border-radius: 50%;
      margin-right: 8px;
    }
    padding: 1.25rem 1.5rem;
  }

  .tabsLinks {
    min-height: 300px;
    overflow: auto;
    @include scrollbar($grey);
    a {
      @include font-poppins(16, $white, 600, 24, normal);
      text-decoration: none;
      div {
        padding: 0.75rem 1.5rem;
        svg {
          margin-left: 0.5rem;
        }
      }
      &:visited {
        color: $white;
      }
    }
  }

  .logoutButton {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5.25rem;
    @include font-poppins(16, $white, 600, 24, normal);
    padding: 1.5rem 1.5rem 2rem;
    border-top: 1px solid $dark_blue;
    @include flex(row, space-between, center);
  }

  .logout:hover {
    cursor: pointer;
  }

  .help:hover {
    cursor: pointer;
  }

  .brandingMobile {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    @include flex(row, space-between, flex-end);
    padding: 1.5rem;
    background: $dark_blue;

    a {
      @include font-poppins(12, $white, normal, 18, normal);
    }
  }

  @media only screen and (max-height: 600px) {
    height: unset;
    .logoutButton {
      position: relative;
      left: unset;
      right: unset;
      bottom: unset;
      padding: 1rem 1.5rem 1rem;
    }

    .brandingMobile {
      position: relative;
      left: unset;
      right: unset;
      bottom: unset;
    }
  }
}

.hbmenuAvatar {
  .avatar {
    border-radius: 50%;
    margin-right: 1rem;
  }
}

.hbmenuName {
  @include font-open-sans(16, $lilac, 600, 22, normal);
}

.hamburgerMenuModal {
  margin-top: 1rem;
  min-width: 100vw !important;
  min-height: 100vh !important;
  background-color: $vc_background_blue !important;
}

.customMenuItem {
  display: flex;
  justify-content: space-around;
  width: 100% !important;
}
.customMenuItemLabel {
  padding-right: 1rem;
  @include font-open-sans(14, $purple, 600, 22, normal);
}

.customMenuLabel {
  @include font-open-sans(14, $white, bold, 22, normal);
}
