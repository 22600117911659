@mixin font-poppins($size: false, $color: false, $weight: false, $height: false, $style: false) {
  font-family: Poppins, sans-serif;
  @if $size {
    @include font-size($size);
  }
  @if $color {
    color: $color;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $height {
    @include line-height($height);
  }
  @if $style {
    font-style: $style;
  }
}

@mixin font-montserrat($size: false, $color: false, $weight: false, $height: false, $style: false) {
  font-family: Montserrat, sans-serif;
  @if $size {
    @include font-size($size);
  }
  @if $color {
    color: $color;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $height {
    @include line-height($height);
  }
  @if $style {
    font-style: $style;
  }
}

@mixin font-roboto($size: false, $color: false, $weight: false, $height: false, $style: false) {
  font-family: Roboto, sans-serif;
  @if $size {
    @include font-size($size);
  }
  @if $color {
    color: $color;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $height {
    @include line-height($height);
  }
  @if $style {
    font-style: $style;
  }
}

@mixin font-open-sans($size: false, $color: false, $weight: false, $height: false, $style: false) {
  font-family: Open Sans, sans-serif;
  @if $size {
    @include font-size($size);
  }
  @if $color {
    color: $color;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $height {
    @include line-height($height);
  }
  @if $style {
    font-style: $style;
  }
}

@mixin font-segoe-ui($size: false, $color: false, $weight: false, $height: false, $style: false) {
  font-family: 'Segoe UI', sans-serif;
  @if $size {
    @include font-size($size);
  }
  @if $color {
    color: $color;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $height {
    @include line-height($height);
  }
  @if $style {
    font-style: $style;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

$breakpoints: (
  'phone': 400px,
  'phone-wide': 480px,
  'phablet': 560px,
  'tablet-small': 640px,
  'mui-small': 704px,
  'tablet': 768px,
  'tablet-wide': 1025px,
  'desktop': 1248px,
  'desktop-wide': 1440px,
  'desktop-large': 1441px,
  '1080p': 1920px,
  '4k': 3840px
);
@mixin media-query($device, $type: min) {
  @if map_has_key($breakpoints, $device) {
    $width: map_get($breakpoints, $device);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

$heightbreakpoints: (
  'short': 620px,
  'medium': 760px,
  'normal': 850px
);
@mixin media-height-query($device, $type: min) {
  @if map_has_key($heightbreakpoints, $device) {
    $height: map_get($heightbreakpoints, $device);
    @if $type == max {
      $height: $height - 1px;
    }
    @media only screen and (#{$type}-height: $height) {
      @content;
    }
  }
}

@mixin font-size($size, $base: 16) {
  font-size: $size;
  font-size: ($size / $base) * 1rem;
}

@mixin line-height($size, $base: 16) {
  line-height: $size;
  line-height: ($size/$base) * 1rem;
}

@mixin flex-center($direction: row) {
  display: flex;
  flex-direction: $direction;
  justify-content: center;
  align-items: center;
}

@mixin flex($direction: row, $justify: center, $align: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin inline-flex($direction: row, $justify: center, $align: center) {
  display: inline-flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}
@mixin scrollbar($color, $invisible: false, $thin: false) {
  &::-webkit-scrollbar {
    width: 12px;
    @if $invisible {
      width: 0;
    }
    @if $thin {
      width: 2px;
    }
    height: 18px;
  }
  &::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    @if $thin {
      border: 2px solid rgba(0, 0, 0, 0);
    }
    background-clip: padding-box;
    border-radius: 30px;
    background-color: $color;
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
