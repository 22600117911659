@import '../../../utils/stylesheet/mixins';
@import '../../../utils/stylesheet/colors';

.contentWrapper {
  @include flex-center(column);
  gap: 1.7rem;
  @include media-query('phone-wide', max) {
    gap: 1rem;
  }
  .bodyWrapper {
    @include flex-center(column);
    text-align: center;
    gap: 1rem;
    .text {
      @include font-poppins(24, $pure_white, 500, 32);
    }
    .message {
      @include font-poppins(16, $pure_white, 500, 21);
    }
    .load {
      width: 24px;
      height: 24px;
      border: 2px solid $purple;
      border-radius: 50%;
    }
    .partnersDetail {
      @include flex-center(column);
      gap: 1rem;
      .image {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid $pure_white;
      }
    }
    .button {
      font-weight: 500;
      font-size: 16px;
      margin-top: -0.25rem;
    }
  }
}
