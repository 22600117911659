@import '../../utils/stylesheet/mixins';
@import '../../utils/stylesheet/colors';

.virtualCoffeeContainer {
  width: 100%;
  height: 100vh;
  background-color: $vc_background_blue;

  .virtualCoffeeWrapper {
    height: inherit;
    @include flex-center(column);
    gap: 2rem;

    @include media-query('tablet-wide', max) {
      gap: 2.75rem;
    }

    .peersAndActionsWrapper {
      @include flex-center();
      gap: 5.75rem;
      width: 85%;
      @include media-query('phone-wide', max) {
        flex-direction: column;
      }

      @include media-query('tablet-wide', max) {
        gap: 3.75rem;
      }

      .videoWrapper {
        @include flex-center(column);
        width: -webkit-fill-available;
        gap: 1.25rem;
      }
    }
  }
}

.actionsWrapper {
  width: 50%;
  @include media-query('phone-wide', max) {
    width: 100%;
  }
}
