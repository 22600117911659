@import '../../../utils/stylesheet/colors.scss';
@import '../../../utils/stylesheet/mixins.scss';

.virtualCoffeeContentWrapper {
  @include flex(column, initial, initial);
  gap: 1.5rem;

  .goBackButton {
    @include flex(row, initial, center);
    gap: 0.75rem;
    @include font-poppins(16, $white, 600, 24, normal);
    cursor: pointer;
  }

  .topContainer {
    @include flex(row, space-between, initial);

    .welcomeText {
      @include font-montserrat(24, $white, 600, 36, normal);
    }

    .dropdown {
      width: 172px;
    }
  }

  .container {
    padding: 30px;
    width: unset;
    border-radius: 4px;
    @include flex(column, initial, initial);
    gap: 30px;

    @include media-query('phone-wide', max) {
      padding: 15px;
    }

    .title {
      @include font-montserrat(14, $white, 400, 26, normal);

      .engagement {
        @include font-montserrat(18, $white, 600, 26, normal);
      }
    }

    .comingSoonContainer {
      width: 100%;
      position: relative;

      @include media-query('tablet', max) {
        height: 250px;
      }

      .comingSoonText {
        @include font-montserrat(18, $white, 600, 26, normal);
        position: absolute;
        left: 50%;
        top: 45%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }

      .comingSoonImg {
        width: 100%;

        @include media-query('tablet', max) {
          height: 100%;
        }
      }
    }
  }
}
