@import '../../../utils/stylesheet/colors.scss';
@import '../../../utils/stylesheet/mixins.scss';

.container {
  padding: 30px;
  border-radius: 4px;
  @include flex(column, initial, initial);
  gap: 30px;
  width: unset;

  @include media-query('phone-wide', max) {
    padding: 15px;
  }

  .title {
    @include font-montserrat(18, $white, 600, 26, normal);
  }
}

.emptyState {
  padding: 30px 0px 0px 0px;

  .emptyStateTitle {
    padding: 0px 30px;
  }

  .comingSoonContainer {
    padding: 30px;
    .lineChartImg {
      width: 100%;
    }
  }
}
