@import '../../../utils/stylesheet/mixins.scss';
@import '../../../utils/stylesheet/colors.scss';

.notificationSection {
  background-color: $trivia_section_bg;
  border-radius: 4px;
  padding: 2rem;

  .header {
    gap: 12px;
    @include flex(column, flex-start, flex-start);
    margin-bottom: 2rem;

    .title {
      @include font-montserrat(18, $white, 600, 29, normal);
    }

    .subTitle {
      @include font-montserrat(18, $lilac_white, 400, 22, normal);
    }
  }

  .notificationsWrapper {
    @include font-montserrat(16, $pure_white, 500, 22, normal);
    @include flex(column, space-between, normal);
    gap: 33px;

    .notificationtext {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      padding-bottom: 16px;
    }

    .upgradePlan {
      margin-top: 1rem;
      cursor: pointer;
      @include font-poppins(14, $purple, 500, 21, normal);
      &:hover {
        text-decoration: underline;
      }
    }

    .disableText {
      opacity: 0.4;
    }
  }
}
