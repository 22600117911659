@import '../../../utils/stylesheet/colors.scss';
@import '../../../utils/stylesheet/mixins.scss';

.awardSectionWrapper {
  @include media-query('phone-wide', max) {
    display: block;
    padding: 1rem !important;
  }
  @include media-query('tablet', max) {
    padding: 0.5rem 2rem;
  }
  background-color: $trivia_section_bg;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  padding: 0.5rem 3rem 0.5rem 1rem;
  margin-bottom: 1rem;
  .awardsImagesWrapper {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .awardContent {
    @include media-query('tablet', max) {
      @include font-montserrat(13, $dull_white, 600, 20, normal);
      width: 40%;
    }
    @include media-query('phone-wide', max) {
      @include font-montserrat(15, $dull_white, 600, 20, normal);
      width: 100%;
    }
    @include font-montserrat(18, $dull_white, 600, 32, normal);
    height: fit-content;
  }
  .awardsImagesWrapper {
    @include media-query('phone-wide', max) {
      padding-top: 0.5rem;
    }
    .awards {
      padding-right: 1rem !important;
      &:nth-child(5) {
        @include media-query('phone-wide', max) {
          width: 70px;
          height: 85px;
        }
      }
      @include media-query('phone-wide', max) {
        width: 80px;
        height: 101px;
      }
      @include media-query('tablet', max) {
        width: 70px;
        height: 101px;
        padding-right: 0rem;
      }
      padding-right: 0.5rem;
      width: 92px;
      height: 122px;
    }
    .americanAward {
      padding-top: 0.5rem;
      height: 107px;
      padding-right: 0rem !important;
    }
  }
}
