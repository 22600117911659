@import '../../../utils/stylesheet/mixins';
@import '../../../utils/stylesheet/colors';

.videoContainer {
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(2, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  padding-top: 56.25%;
  height: 0px;
  position: relative;
  margin-top: 10%;

  .loader {
    position: absolute;
    top: 50%;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;

    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .mainVideoWrapper {
    border-radius: 16px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .userVideoContainer {
    position: absolute;
    right: 1rem;
    bottom: 2rem;
    width: 35%;
    height: 20vh;
    min-width: 150px;
    max-height: 150px;

    @include media-query('phone-wide', max) {
      height: 85px;
      min-width: 85px;
    }
    .userVideoWrapper {
      position: relative;

      .userVideo {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        @include media-query('tablet-wide', max) {
          margin-top: 25%;
        }
      }

      .videoText {
        position: absolute;
        left: 1rem;
        bottom: 1rem;
        @include font-poppins(14, $pure_white, 600, 21, normal);

        @include media-query('phone-wide', max) {
          left: 0.5rem;
          bottom: 0.5rem;
          @include font-poppins(12, $pure_white, 600, 18, normal);
        }
      }
    }

    .userVideoDisabled {
      @include flex-center();
      background-color: $vc-light-blue;
      width: 100%;
      height: 100%;
      border-radius: 16px;

      .user2Image {
        width: 64px;
        height: 64px;
        border-radius: 100px;
        border: 2px solid $pure_white;

        @include media-query('phone-wide', max) {
          width: 36px;
          height: 36px;
        }

        @include media-height-query('medium', max) {
          width: 48px;
          height: 48px;
        }
      }
    }
  }

  .hide {
    display: none;
  }

  .MicOn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .small {
    width: 20px;
  }

  .peerName {
    position: absolute;
    bottom: 1rem;
    left: 1.25rem;
    @include font-poppins(14, $pure_white, 600, 21, normal);

    @include media-query('phone', max) {
      left: 0.5rem;
      bottom: 0.5rem;
      @include font-poppins(12, $pure_white, 600, 18, normal);
    }
  }
}

.mainVideoDisabled {
  background-color: $vc-dark-blue;
  @include flex-center();

  .user1Image {
    width: 20%;
    border-radius: 100px;
    border: 2px solid $pure_white;
    position: absolute;
    top: 30%;

    @include media-query('phone-wide', max) {
      width: 100px;
      height: 100px;
    }
  }
}
