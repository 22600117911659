@import '../../../utils/stylesheet/colors.scss';
@import '../../../utils/stylesheet/mixins.scss';

.headerWrapper {
  margin-bottom: 0.75rem;
}

.container {
  position: relative;
  padding: 24px;

  .coffeeImage {
    position: absolute;
    top: 0;
    left: 0;
  }
}
