@import '../../../utils/stylesheet/mixins';
@import '../../../utils/stylesheet/colors';

@keyframes toast-in-bottom {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.componentWrapper {
  @include flex(column, center, center);
  gap: 2rem;
  margin: 3rem;
  position: relative;
  @include media-query('phone-wide', max) {
    margin: 0;
  }
  .messageWrapper {
    @include flex(row, space-between);
    background-color: $pure_white;
    position: fixed;
    margin-bottom: 6.7rem;
    animation: toast-in-bottom 0.7s;
    padding: 0.75rem 1.5rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 40px;
    text-align: center;
    max-width: 600px;

    @include media-query('phone-wide', max) {
      max-width: 70%;
      bottom: 10%;
      margin-bottom: 0rem;
    }

    .message {
      @include font-poppins(14, $vc_grey, 500, 21);
      cursor: default;
      transition: transform 0.6s ease-in-out;
    }
    .closeIcon {
      margin-left: 2rem;
      cursor: pointer;
    }
  }
  .callControlsWrapper {
    @include flex(row, center, center);
    gap: 1rem;
    .fixed {
      position: fixed;
      bottom: 10px;
      @include media-query('phone-wide', max) {
        position: inherit;
      }
    }
    .circle {
      width: 48px;
      height: 48px;
      border: 2px solid $purple;
      border-radius: 50%;
      @include flex-center(row);
      box-sizing: border-box;
      cursor: pointer;
    }
    .red {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: $vc_red;
      @include flex-center(row);
      cursor: pointer;
    }
    .phone {
      width: 64px;
      height: 48px;
      border-radius: 100px;
      background: $vc_red;
      @include flex-center(row);
      cursor: pointer;
    }
  }
}
