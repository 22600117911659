@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.heading {
  @include font-montserrat(24, $white, 600, 29, normal);
  margin-bottom: 1.5rem;
  @include flex(row, flex-start, center);
}
.gameZoneWrapper {
  @include media-query(phablet) {
    width: 87%;
  }
  @include media-query(desktop) {
    width: 90%;
  }
  .gameCardContainer {
    border: 1px solid $border_blue;
    padding: 2rem 1.5rem;
    border-radius: 4px;
    margin-bottom: 1.5rem;

    .descriptionWrapper {     
      @include media-query('phone-wide', max) {
        @include flex(column, flex-start, flex-start);
      }
      @include flex(row, flex-start, flex-start);
      padding-bottom: 2rem;
      margin-bottom: 1.5rem;
      border-bottom: 1px solid $grey;
      .leftSide {
        min-width: 130px;
        margin-right: 1rem;
      }
      .rightSide {
        width: -webkit-fill-available;
        width: -moz-available;
        .heading {
          @include flex(row, space-between, center);
          margin-bottom: 0.5rem;
          .name {
            @include font-montserrat(18, $lilac_white, 600, 22, normal);
            @include media-query('phone-wide', max) {
              padding-top: 1rem;
          }
          }

          .criteria {
            @include flex(row, space-around, center);
            flex-wrap: wrap;
            .tagWrapper {
              @include flex(row, space-between, center);
              .dot {
                width: 0.25rem;
                height: 0.25rem;
                background: $lilac_white;
                border-radius: 50%;
                margin: 0 0.25rem;
              }
              .tag {
                margin: 0;
              }
            }
          }

          @include media-query('tablet-wide', max) {
            @include flex(column, flex-start, flex-start);
            .name {
              margin-bottom: 1rem;
            }
            .criteria {
              @include flex(column, flex-start, flex-start);
              .tagWrapper {
                margin-bottom: 0.25rem;
                .dot {
                  display: none;
                }
              }
            }
          }
        }

        .description {
          @include font-montserrat(14, $lilac_white, normal, 17, normal);
        }

        .subtitle {
          @include font-montserrat(14, $lilac_white, 600, 17, normal);
          margin: 1.25rem 0 0.75rem 0;
        }
      }
    }
  }

  .footer {
    @include flex(row, flex-end, center);
  }
}

.loading {
  @include flex(row, space-between, flex-start);
  .rightLoading {
    width: 100%;

    .heading {
      @include flex(row, space-evenly, center);
      .text {
        min-width: max-content;
        @include font-montserrat(18, $lilac_white, 600, 22, normal);
      }
      margin-bottom: 1rem;
    }

    .loadingButton {
      margin-top: 2rem;
      float: right;
      opacity: 0.3;
    }

    @include media-query('tablet-wide', max) {
      .heading {
        @include flex(column, flex-start, flex-start);

        .text {
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.pictionaryWrapper {
  background-color: red;
  height: 100vh;
}

.titleSection {
  margin-bottom: 1rem;
  font-size: 28px;
  .title {
    @include font-montserrat(24, $pure_white, 600, 30, normal);
    text-align: left;
    padding-top: 5px;
  }
}
