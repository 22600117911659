@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.content{
  padding-top: 0px !important;
}

.billingLayoutWrapper {
  @include media-query('tablet-wide', max) {
    padding: 0rem 2rem;
  }
  @include media-query('phone-wide', max) {
    padding: 0rem 1rem;
  }
  .currentPlanSection,
  .pricingTableSection {
    .sectionHeading {
      margin-bottom: 0.7rem;
      @include flex(row, space-between, center);
      @include font-montserrat(24, $white, 600, 36, normal);
      .titleIcon {
        font-size: 35px;
      }
      .headerButton {
        @include flex(row, space-around, center);
      }
      .managingButtons {
        @include flex(row, space-around, center);
      }
      .slackManagePlanButton {
        background-color: transparent !important;
        width: 200px;
        @include media-query('phone-wide', max) {
          width: 100%;
        }
      }
    }

    .button {
      text-transform: none !important;
    }

    .newSectionHeading {
      margin-bottom: 30px;

      @include media-query('tablet-wide', max) {
        margin-bottom: 20px;
      }
    }
    .activeUsers {
      position: absolute;
      left: 50%;
      top: 10px;
      border-bottom: 2px dashed $white;
      @include font-montserrat(16, $white, 500, 22, normal);

      @include media-query('tablet-wide', max) {
        top: 10px;
      }
    }
    .currentPlanDetails {
      margin-top: -1.25rem;
      @include media-query('tablet-wide', max) {
        margin: 0rem -1rem;
      }
    }
  }
  .rangeSliderWrapper {
    margin: 2rem 1rem 2rem 24%;

    @include media-query('tablet-wide', max) {
      margin: 5rem 0rem 0rem 0rem;
    }
    @include media-query('phone-wide', max) {
      width: 80%;
      margin: 40px auto;
    }
  }
  .pricingCalculator {
    position: relative;
    z-index: 1;
    background-color: $grey;
    padding: 0.1rem 0rem;
    @include media-query('tablet-wide', max) {
      top: -0.2rem;
      margin-left: -1px;
      padding-top: 0.5rem;
      padding-bottom: 1rem;
    }
  }

  .newPricingCalculator {
    @include media-query('tablet-wide', max) {
      display: flex;
      margin-top: 20px;
    }
  }

  .pricingCardWrapper {
    margin-bottom: 2rem;
  }
  .enterpriseBanner {
    margin: 1rem 0rem;
  }
}

.customCurrentPlanSection {
  margin-bottom: 35px;
}
