@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.container {
  background: #b0a3ff;

  @include flex(row, space-between, center);
  padding: 32px 64px;

  .freeTrialText {
    @include font-poppins(20, $dark_blue, 700, 20, normal);
    text-transform: uppercase;
  }

  .accessTriviaText {
    @include font-poppins(14, $dark_blue, 400, 21, normal);
    margin-top: 12px;
  }

  .getSubscriptionBtn {
    width: fit-content;
    font-weight: 600;
    font-size: 16px;
    text-transform: none !important;
  }
}
