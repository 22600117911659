@import '../../utils/stylesheet/mixins.scss';
@import '../../utils/stylesheet/colors.scss';

.settingsWrapper {
  .title {
    @include font-montserrat(24, $white, 600, 29, normal);
    margin-bottom: 1.5rem;
  }

  .adminView {
    @include flex(column, space-between, initial);
    gap: 20px;
  }
}
