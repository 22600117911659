@import '../../../utils/stylesheet/mixins';
@import '../../../utils/stylesheet/colors';

.wrapper {
  background: $grey;
  height: 100vh;
  @include flex(column, '', center);
  padding: 2rem;

  .contentWrapper {
    padding-top: 150px;
    @include flex(column, flex-start, center);
    gap: 1.7rem;

    @include media-query('phone-wide', max) {
      gap: 1rem;
      padding-top: 50px;
      width: 100%;
    }

    .infoWrapper {
      @include flex-center(column);
      gap: 1.7rem;

      @include media-query('phone-wide', max) {
        gap: 1rem;
      }

      .headingText {
        @include font-poppins(24, $pure_white, 500, 28);
        text-align: center;
      }

      .connectivityText {
        @include font-poppins(14, $pure_white, 500, 20);
        text-align: center;
      }

      .buttonContainer {
        margin-top: -0.25rem;
        display: flex;

        .rejoinButton {
          @include font-poppins(16, $pure_white, 500, 24);
          background-color: transparent;
          margin-right: 10px;
          min-width: 30%;
          padding: 0.5rem 0.75rem;
        }

        .exploreButton {
          @include font-poppins(16, $pure_white, 500, 24);
          min-width: 30%;
          padding: 0.5rem 0.75rem;
        }
      }
    }

    .exprienceContainer {
      text-align: center;
      width: 100%;
      @include flex-center(column);
      gap: 0.8rem;

      @include media-query('phone-wide', max) {
        gap: 0.5rem;
        width: 90%;
      }

      .exprienceText {
        @include font-poppins(16, $pure_white, 500, 25);
      }

      .starsContentWrapper {
        @include flex-center(column);
        width: 225px;
        .starsContainer {
          display: flex;

          .star {
            margin-right: 20px;

            &:last-child {
              margin-right: 0px;
            }
          }
        }

        .starsTextContainer {
          @include flex(row, space-between);
          width: 100%;
          margin-top: 12px;
          @include font-poppins(12, $pure_white, 0);
        }
      }

      .submitButton {
        @include font-poppins(16, $pure_white, 500, 24);
        background-color: transparent;
        min-width: 30%;
        padding: 0.5rem 0.75rem;
      }
    }

    .thanksContainer {
      @include flex(row);
      @include font-poppins(12, $pure_white, 500, 10);

      .tick {
        margin-right: 5px;
      }
    }
  }
}
