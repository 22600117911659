@import '../../utils/stylesheet/mixins.scss';
@import '../../utils/stylesheet/colors.scss';

.emptyStateFooter {
  @include flex(row, center, center);
  padding: 0.75rem;
  background-color: $light_voilet;
  position: relative;
  border-radius: 0 0 4px 4px;

  .footerDescription {
    @include font-poppins(14, $pure_white, 500, 21, normal);
  }

  .playGamesButton {
    position: absolute;
    right: 2rem;
    @include flex(row, initial, center);
    gap: 0.5rem;
    @include font-poppins(16, $pure_white, 500, 24, normal);
    cursor: pointer;
  }
}

@include media-query('phone-wide', max) {
  .emptyStateFooter {
    @include flex(row, flex-start, center);

    .footerDescription {
      max-width: 200px;
      @include font-poppins(12, $pure_white, 400, 18, normal);

      @include media-query('phone', max) {
        max-width: 160px;
      }
    }

    .playGamesButton {
      right: 1rem;
      @include font-poppins(14, $pure_white, 500, 20, normal);
    }
  }
}
