@import '../../utils/stylesheet/mixins.scss';
@import '../../utils/stylesheet/colors.scss';

.overviewWrapper {
  border-radius: 4px;
  background-color: $trivia_section_bg;
  @include flex(column, initial, initial);
  gap: 1.5rem;

  .overviewHeader {
    @include flex(row, space-between, center);
    margin: 1rem 1.5rem 0 1.5rem;
    gap: 1rem;

    .title {
      @include font-montserrat(18, $dull_white, 600, 26, normal);
    }

    .dropdowns {
      @include flex(row, initial, center);
      gap: 1.25rem;
    }
  }

  .gamesAnalytics {
    @include flex(row, space-between, center);
    margin: 0rem 1.5rem 0 1.5rem;

    .gameDetailsWrapper {
      @include flex(row, initial, center);
      gap: 1.25rem;

      .analytcsWrapper {
        @include flex(column, initial, initial);
        gap: 0.25rem;

        .analyticsValue {
          @include flex(row, initial, center);
          gap: 0.3rem;
          @include font-poppins(20, $dull_white, 600, 30, normal);
        }
        .analyticsLabel {
          @include font-poppins(12, $greyish_white, 500, 18, normal);
        }
      }
    }
  }

  .overviewFooter {
    padding: 0.75rem;
    border-top: 0.5px solid $light_voiled_blue;
    margin: 0 3.5rem 0 3.5rem;
    @include flex(row, center, center);
    .diveDeeperButton {
      @include flex(row, center, center);
      gap: 0.5rem;
      cursor: pointer;
      @include font-poppins(14, $voilet_blue, 600, 20, normal);
      svg {
        fill: $voilet_blue;
      }
    }
  }

  .emptyStateFooter {
    @include flex(row, center, center);
    padding: 0.75rem;
    background-color: $light_voilet;
    position: relative;
    border-radius: 0 0 4px 4px;

    .footerDescription {
      @include font-poppins(14, $pure_white, 500, 21, normal);
    }

    .playGamesButton {
      position: absolute;
      right: 2rem;
      @include flex(row, initial, center);
      gap: 0.5rem;
      @include font-poppins(16, $pure_white, 500, 24, normal);
      cursor: pointer;
    }
  }

  @include media-query('tablet-small', max) {
    .gamesAnalytics {
      margin: 0rem 1.5rem 0 1.5rem;
      display: grid;
      grid-template-columns: auto auto;

      .gameDetailsWrapper {
        gap: 0.75rem;
        margin-bottom: 1.5rem;

        .analytcsWrapper {
          @include flex(column, initial, initial);
          gap: 0.05rem;

          .analyticsValue {
            @include flex(row, initial, center);
            gap: 0.3rem;
            @include font-poppins(20, $dull_white, 600, 30, normal);
          }
          .analyticsLabel {
            @include font-poppins(12, $greyish_white, 500, 18, normal);
          }
        }
      }
    }
  }

  @include media-query('phablet', max) {
    .overviewHeader {
      @include flex(row, space-between, center);
      margin: 1rem 1.5rem 0 1.5rem;

      .dropdowns {
        @include flex(row, initial, center);
        gap: 0.75rem;

        input {
          padding-left: 0.5rem;
          padding-right: 1rem;
        }
      }
    }

    .icon {
      width: 44px;
      height: 44px;
    }

    .gameDetailsWrapper {
      gap: 0.75rem;
      margin-bottom: 1.5rem;

      .analytcsWrapper {
        @include flex(column, initial, initial);
        gap: 0.05rem;

        .analyticsValue {
          @include flex(row, initial, center);
          gap: 0.3rem;
          @include font-poppins(14, $dull_white, 600, 21, normal);
        }
        .analyticsLabel {
          @include font-poppins(12, $greyish_white, 500, 18, normal);
        }
      }
    }
  }

  @include media-query('phone-wide', max) {
    gap: 1.40625rem;

    .overviewHeader {
      @include flex(column, flex-start, flex-start);
      margin: 1rem 0.75rem 0 0.75rem;
      gap: 0.75rem;

      .title {
        @include font-montserrat(14, $dull_white, 600, 21, normal);
      }

      .dropdowns {
        gap: 0.75rem;
      }
    }

    .gamesAnalytics {
      margin: 0rem 0.75rem 0 0.75rem;
      flex-wrap: wrap;

      .icon {
        width: 36px;
        height: 36px;
      }

      .gameDetailsWrapper {
        gap: 0.75rem;
        margin-bottom: 1.5rem;

        .analytcsWrapper {
          @include flex(column, initial, initial);
          gap: 0.05rem;

          .analyticsValue {
            @include flex(row, initial, center);
            gap: 0.3rem;
            @include font-poppins(14, $dull_white, 600, 21, normal);
          }
          .analyticsLabel {
            @include font-poppins(12, $greyish_white, 500, 18, normal);
          }
        }
      }
    }

    .overviewFooter {
      margin: 0 0.5rem 0 0.5rem;
    }

    .emptyStateFooter {
      @include flex(row, flex-start, center);

      .footerDescription {
        max-width: 200px;
        @include font-poppins(12, $pure_white, 400, 18, normal);

        @include media-query('phone', max) {
          max-width: 160px;
        }
      }

      .playGamesButton {
        right: 1rem;
        @include font-poppins(14, $pure_white, 500, 20, normal);
      }
    }
  }
}

.paddingBottom {
  padding-bottom: 1.5rem;
}

.displayNone {
  display: none !important;
}
