@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.inlineError {
  svg {
    margin-right: 4px;
  }
  @include font-montserrat(14, $error, bold, 29.64, normal);
  letter-spacing: 0.08em;
}

.inlineSuccess {
  svg {
    margin-right: 4px;
  }
  @include font-montserrat(14, $green, bold, 29.64, normal);
  letter-spacing: 0.08em;
}

@include media-query('phone-wide', max) {
  .inlineError,
  .inlineSuccess {
    font-size: 10px;
  }
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}
