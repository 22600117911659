@import '../../../utils/stylesheet/mixins';
@import '../../../utils/stylesheet/colors';

.pageHeader {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  top: 0;
  left: 0;
  background-color: $dark_blue;
  padding: 0.5rem 2rem;
  z-index: 5;
  @include media-query('phone-wide', max) {
    padding: 0.5rem 1rem;
  }
  .virtualCoffeeWrapper {
    @include flex(row, flex-start);
    .triviaIcon {
      position: fixed;
      @include media-query('phone-wide', max) {
        height: 3%;
      }
    }
    .virtualCoffee {
      margin: auto;
      @include flex-center(row);
      .hotCoffee {
        position: relative;
        right: 8px;
        bottom: 2px;
      }
      .text {
        @include font-poppins(14, $pure_white, 500, 52);
      }
    }
  }
  .headerWrapper {
    @include flex(row, space-between, center);
    .titleWrapper {
      @include flex(row, space-between, center);
      gap: 4rem;
      .companyWrapper {
        @include flex(row, flex-start, center);
        letter-spacing: 0.75px;
        @include media-query('tablet-wide', max) {
          display: none;
        }
        .company {
          @include font-poppins(14, #ffffff, 500, 21, normal);
        }
      }
    }
    .userWrapper {
      @include flex(row, flex-start, center);
      button {
        color: #fff;
        width: 90%;
        @include flex(row, flex-start, center);
      }
    }
  }
}
.avatarWrapper {
  pointer-events: none;
}

.avatar {
  border-radius: 50%;
  margin-right: 1rem;
}
.arrow {
  content: '';
  border: solid $purple;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transition: all 0.2s ease-in;
  margin-left: 0.8rem;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-bottom: 0.2rem;
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin-bottom: 0rem;
}
