@import '../../../utils/stylesheet/mixins';
@import '../../../utils/stylesheet/colors';

.contentWrapper {
  @include flex-center(column);
  background: linear-gradient(295.92deg, #051129 0%, rgba(33, 25, 65, 0.94) 98.72%);
  height: 100vh;

  .wrapper {
    @include flex(column, space-between);
    height: calc(50vh + 75px);
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
    .topContainer {
      @include flex-center(column);
      gap: 1.2rem;
      height: 150px;
      width: fit-content;

      .text {
        @include font-poppins(24, $pure_white, 500, 32);
        @include media-query('phone-wide', max) {
          @include font-poppins(16, $pure_white, 500, 32);
          text-align: center;
          width: 200px;
        }
      }
      .loader {
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 2s linear infinite;

        /* Safari */
        @-webkit-keyframes spin {
          0% {
            -webkit-transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
          }
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}
