@import '../../../utils/stylesheet/colors.scss';
@import '../../../utils/stylesheet/mixins.scss';

.stepsContainer {
  background-color: $dark_blue;
  border-radius: 8px;
  height: 100%;
  padding: 30px 0px;

  @include media-query('desktop', max) {
    margin-top: 50px;
  }

  .steps {
    @include flex(column, '', center);

    .rowLicenses {
      @include flex(column, '', center);
      padding: 0px 20px;

      .threeStepsText {
        @include font-montserrat(22, $white, 600, 24, normal);
        margin-bottom: 20px;
        text-align: left;
        width: 100%;
      }

      .stepOneAndTwo {
        @include flex(row, '', center);

        @include media-query('tablet', max) {
          @include flex(column, '', center);
        }
      }

      .stepOne {
        padding-right: 5%;
        width: 550px;

        @include media-query('desktop-wide', max) {
          width: 50%;
          padding-right: 1%;
        }

        @include media-query('tablet', max) {
          width: 90%;
          margin-bottom: 20px;
        }
      }

      .stepTwo {
        padding-left: 5%;
        width: 550px;

        @include media-query('desktop-wide', max) {
          width: 50%;
          padding-left: 1%;
        }

        @include media-query('tablet', max) {
          width: 90%;
        }
      }
    }

    .stepThree {
      margin-top: 20px;
      width: 550px;

      @include media-query('desktop-wide', max) {
        width: 50%;
      }

      @include media-query('tablet', max) {
        width: 90%;
      }
    }
  }
}
