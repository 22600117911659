@import '../../../utils/stylesheet/colors.scss';
@import '../../../utils/stylesheet/mixins.scss';

.cardsContainer {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;

  .cardWraper {
    padding: 1.5rem;
    border-radius: 4px;
    @include flex(column, initial, initial);

    .cardTitle {
      @include font-montserrat(22, $trivia_section_bg, 700, 32, normal);
      margin-bottom: 0.5rem;
    }

    .titleNonEmptyState {
      margin-bottom: 1.25rem;
    }

    .cardSubTitle {
      @include font-montserrat(14, $trivia_section_bg, 500, 20, normal);
      margin-bottom: 1.25rem;
    }

    .subTitleNonEmptyState {
      margin-bottom: 0.5rem;
    }

    .highlightText {
      color: $highlight_purple;
    }
  }

  .gameCard {
    background: $light_pink url('../../../assets/images/home/pinkWave.svg') bottom left no-repeat;
  }

  .automationCard {
    background: $light_blue url('../../../assets/images/home/blueWave.svg') bottom left no-repeat;
  }

  .kytCard {
    background: $shade_green url('../../../assets/images/home/greenWave.svg') bottom left no-repeat;
  }

  .vcCard {
    background: $shade_orange url('../../../assets/images/home/orangeWave.svg') bottom left
      no-repeat;
  }

  .gameCard,
  .automationCard,
  .kytCard,
  .vcCard {
    background-size: contain;
  }
}

@include media-query('tablet-small', max) {
  .cardsContainer {
    grid-template-columns: auto;
  }
}

@include media-query('phone-wide', max) {
  .cardsContainer {
    grid-template-columns: auto;
    gap: 0.75rem;

    .cardWraper {
      padding: 1rem;

      .cardTitle {
        @include font-montserrat(16, $trivia_section_bg, 700, 24, normal);
        margin-bottom: 0.25rem;
      }

      .titleNonEmptyState {
        margin-bottom: 0.75rem;
      }

      .cardSubTitle {
        @include font-montserrat(12, $trivia_section_bg, 500, 18, normal);
        margin-bottom: 0.75rem;
      }

      .subTitleNonEmptyState {
        margin-bottom: 0.25rem;
      }

      .cardRedirectButton {
        padding: 0.5rem 1.5rem;
        @include font-poppins(14, $trivia_section_bg, 600, 20, normal);
      }
    }

    .gameCard,
    .automationCard,
    .kytCard,
    .vcCard {
      background-size: 400px;
    }
  }
}

.commonButtonStyles {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: $trivia_section_bg !important;
  background-color: #fff !important;
  padding: 0.5rem 2rem !important;
  border: none !important;
  width: fit-content !important;
}
