@import '../../../../../utils/stylesheet/colors.scss';
@import '../../../../../utils/stylesheet/mixins.scss';

.formContainer {
  @include flex(column);
  gap: 1.25rem;

  .label {
    @include font-montserrat(12, $pure_white, 400, 17, normal);
    letter-spacing: 0.5px;
  }

  .subLabel {
    @include font-montserrat(12, $dull_grey, 400, 18, normal);
    opacity: 0.8;
    letter-spacing: 0.5px;
  }

  .dateAndTimezoneContainer {
    @include flex(row, space-between, center);
    gap: 16px;
    width: 100%;
  }

  .categoryDropdownWrapper {
    @include flex(column, space-between, flex-start);
    width: 100%;
    gap: 0.2rem;
  }

  .segmentWrapper {
    @include flex(column, space-between, flex-start);
    width: 100%;
    gap: 0.2rem;
  }

  .noChannelIntegratedNote {
    @include font-montserrat(11, $pure_white, 400, 15, normal);
    margin-top: -15px;
    letter-spacing: 0.5px;
  }
}
