@import '../../../utils/stylesheet/colors.scss';
@import '../../../utils/stylesheet/mixins.scss';

.comparePlanWrapper {
  color: $pure_white;
  .comparePlanBasic {
    @include media-query('phone-wide', max) {
      @include font-montserrat(16, $dull_white, 600, 24, normal);
    }
    .newUserBasic {
      @include media-query('phone-wide', max) {
        .heading {
          @include font-montserrat(16, $dull_white, 600, 24, normal);
        }
        .para {
          @include font-poppins(14, $dull_white, 400, 19.6, normal);
        }
      }
      .heading {
        padding-bottom: 0.5rem;
        @include font-montserrat(18, $dull_white, 600, 26, normal);
      }
    }
    .heading {
      margin-left: 1rem;
      @include font-montserrat(18, $dull_white, 600, 26, normal);
      @include media-query('phone-wide', max) {
        padding-bottom: 0.5rem;
        margin-left: 0rem;
        @include font-montserrat(14, $dull_white, 400, 19.6, normal);
      }
    }
    .billingIntervalsWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto 0;
      background-color: transparent;
      .activeUserCount {
        @include font-poppins(14, $dull_white, 400, 19.6, normal);
        margin: 2rem 0;
        @include media-query('phone-wide', max) {
          margin-bottom: 0rem;
        }
        span {
          @include font-poppins(14, $dull_white, 600, 19.6, normal);
        }
        .channelsWrapper {
          div {
            .para {
              @include font-poppins(14, $dull_white, 400, 19.6, normal);
            }
            @include media-query('phone-wide', max) {
              .para {
                @include font-poppins(14, $dull_white, 400, 19.6, normal);
              }
            }
          }
        }
      }
      .billingIntervals {
        @include media-query('phone-wide', max) {
          margin-left: 1rem;
        }
        @include media-query('tablet-wide', max) {
          margin-top: 2rem;
          min-width: 40%;
        }
        background-color: $dark_blue;
        width: fit-content;
        border-radius: 1.3rem;
        height: fit-content;
        button {
          @include media-query('phone-wide', max) {
            padding: 0.4rem 2rem;
          }
          @include media-query('tablet-wide', max) {
            padding: 0.4rem 2.6rem;
          }
          @include font-poppins(14, $Lavender_Grey, 400, 21, normal);
          background-color: transparent;
          padding: 0.4rem 3rem;
          border: none;
          cursor: pointer;
          &.selected {
            @include font-poppins(14, $purple2, 600, 21, normal);
            border: 1px solid $purple2;
            border-radius: 1rem;
          }
        }
      }
    }
    .plans {
      @include media-query('phone-wide', max) {
        display: flex;
        flex-direction: column-reverse;
        padding-top: 0;
      }
      background-color: $trivia_section_bg;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-evenly;
      width: 100%;
      .Cards {
        @include media-query('phone-wide', max) {
          width: 100%;
          margin-top: 0rem;
        }
        width: 31%;
        .Plan {
          margin-top: 2.5rem;
          position: relative;
          background-color: $Paris_M;
          padding-top: 0%;
          border-radius: 1rem;
          @include media-query('tablet-wide', max) {
            margin-top: 3.5rem;
          }
          @include media-query('phone-wide', max) {
            margin-top: 0rem;
          }

          .popularTag {
            position: absolute;
            bottom: 1rem;
            right: -0.2rem;
            @include media-query('tablet-wide', max) {
              top: 1rem;
              bottom: auto;
            }
          }
          .yearlyStyle {
            @include media-query('tablet-wide', max) {
              top: 1rem !important;
              bottom: auto;
            }
            top: 9rem !important;
          }
          .noChannelTag {
            top: 1rem !important;
          }
          .planInfo {
            @include media-query('tablet-wide', max) {
              display: block;
            }
            @include media-query('tablet-wide', max) {
              padding: 1.5rem 0 1.5rem 0;
            }
            padding: 1.5rem 0 2.5rem 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 8%;
            .userPlan {
              @include font-poppins(16, $dull_white, 500, 22, normal);
            }
            .planDetails {
              @include font-poppins(14, $dull_white, 400, 21, normal);
              span {
                @include font-poppins(14, $dull_white, 600, 21, normal);
              }
            }
            &.noChannels {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: fit-content;
              padding-bottom: 0%;
              .userPlan {
                display: flex;
                justify-content: space-between;
                margin-bottom: 1.5rem;
              }
              .planDetails {
                margin-top: 1.5rem;
                @include font-poppins(16, $dull_white, 500, 22, normal);
                span {
                  @include font-poppins(22, $dull_white, 600, 22, normal);
                }
              }
            }
          }
          &.larger {
            margin-top: 1rem !important;
            padding-top: 1.5rem;
            .planInfo {
              margin-top: 0rem;
              padding: 0rem 0 4rem 0;
              &.noChannels {
                padding-bottom: 0.3rem;
                .userPlan {
                  margin-bottom: 2.7rem;
                }
              }
            }
          }
          &.basicCard {
            .planInfo {
              padding-bottom: 4rem;
              &.noChannels {
                padding-bottom: 1rem;
              }
            }
          }
          .priceDetails {
            width: fit-content;
            @include font-poppins(16, $dull_white, 600, 22, normal);
            .price {
              @include font-poppins(22, $dull_white, 600, 22, normal);
            }
          }
          .minimumPricing {
            padding-top: 0.5rem;
            @include font-poppins(12, $white_grey, 400, 16, normal);
            span {
              @include font-poppins(12, $white_grey, 600, 16, normal);
            }
          }
        }
      }
      button {
        @include font-poppins(16, $pure_white, 600, 21, normal);
        border: 1px solid $purple;
        background-color: transparent;
        border-radius: 8rem;
        padding: 1rem 4rem;
        margin-left: 1rem;
        cursor: pointer;
      }
    }
    ul li {
      @include font-poppins(12, $dull_white, 400, 18, normal);
      margin-bottom: 1rem;
    }
  }
  .anuallySection {
    @include media-query('tablet', max) {
      display: block;
    }
    display: flex;
    justify-content: space-between;
    margin: 0 1rem;
    .savingsSection {
      @include font-montserrat(36, $dull_white, 700, 43.88, normal);
      div {
        @include font-poppins(18, $pure_white, 600, 27, normal);
        margin-top: 1rem;
        span {
          color: $purple;
        }
      }
    }
    .anuallyCard {
      @include media-query('tablet', max) {
        margin-top: 1rem;
        width: 90%;
      }
      width: 35%;
      border-radius: 10px;
      background-color: $Paris_M;
      padding: 1rem;
      .yearlyDetails {
        @include font-poppins(16, $dull_white, 500, 22, normal);
        display: flex;
        justify-content: space-between;
        padding-bottom: 2rem;
        .userPlanDetails {
          @include font-poppins(16, $dull_white, 400, 22, normal);
        }
      }
    }
  }
}

.currentPlanBtn {
  border: 1px solid $pure_white !important;
  padding: 1rem 3rem !important;
  background-color: transparent !important;
}

.offPercent {
  @include font-poppins(12, $Shamrock, 600, 18, normal);
}
.finnalyText {
  @include media-query('tablet', max) {
    display: block;
    @include font-poppins(14, $pure_white, 400, 19.6, normal);
  }
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  .payAnually {
    @include media-query('tablet', max) {
      width: 100%;
    }
    width: 38%;
    button {
      background-color: $purple;
      @include font-poppins(14, $pure_white, 600, 20, normal);
      padding: 0.7rem 5rem;
      border-radius: 2rem;
    }
  }
}

.totalCost {
  margin-bottom: 0.5rem;
  @include font-poppins(18, $Lavender_Grey, 400, 22, normal);
  .crossed {
    color: $Lavender_Grey;
    text-decoration: line-through;
    text-decoration-skip-ink: auto;

    .year {
      @include font-poppins(14, $Lavender_Grey, 400, 22, normal);
    }
  }
}
.payingCost {
  @include font-poppins(22, $dull_white, 600, 22, normal);
  .year {
    @include font-poppins(16, $dull_white, 600, 22, normal);
  }
}

.channelsWrapper {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  width: 97%;
  @include media-query('phone-wide', max) {
    display: block;
  }
  .selectOptions {
    @include media-query('phone-wide', max) {
      padding-bottom: 2rem;
    }
    @include font-montserrat(14, $white2, 400, 18, normal);
    margin-top: 1rem;
    span {
      @include font-montserrat(14, $white2, 600, 18, normal);
    }
  }
  .dropDownStyles {
    margin-top: 0.7rem;
    width: 360px;
    @include media-query('phone-wide', max) {
      width: 97%;
    }
  }

  .billingIntervals {
    background-color: $Jacksons_Purple !important;
    button {
      &.selected {
        @include font-poppins(14, $pure_white, 600, 21, normal);
        border: 1px solid $light_voilet !important;
        border-radius: 1rem;
        background-color: $trivia_section_bg !important;
        color: $light_voilet !important;
      }
    }
  }
}
.exploreText {
  margin: 1rem 0 1rem 1rem;
  @include font-poppins(15, $dull_white, 500, 19.6, normal);
  .stripeButton {
    background: none !important;
    border: none;
    @include font-poppins(15, $purple2, 500, 19.6, normal);
    padding: 0 !important;
    cursor: pointer;
    text-decoration: underline;
  }
  @include media-query('tablet', min) {
    width: 40%;
  }
}

.leftAlign {
  justify-content: flex-start;
}

.billingText {
  @include font-montserrat(12, $white, 200, 30, normal);
}

.error {
  color: $red;
  margin-top: 0.2rem;
  margin-left: 5rem;
  @include font-montserrat(14, $red, 600, 30, normal);
}

.billingTextField {
  border-color: $highlight_dark_blue;
  background-color: $highlight_dark_blue;
  color: white;
  margin-left: 0;
  margin-top: 0.2rem;
  width: 100%;
}

.note {
  span {
    @include font-size(14);
    color: $white;
  }
  div {
    margin: 0 0.25rem;
    display: inline;
  }
}

.uniqueUsersText {
  @include font-montserrat(14, $white, 600, 18, normal);
}

.basicCrossed {
  padding-top: 1.3rem;
  @include font-poppins(22, $dull_white, 600, 22, normal);
  .year {
    @include font-poppins(16, $dull_white, 600, 22, normal);
  }
}
