@import '../../utils/stylesheet/mixins.scss';
@import '../../utils/stylesheet/colors.scss';

.switchWrapper {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $dark_blue;
    border: 1px solid $lilac;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    width: 46px;
    height: 24px;
    margin: 0;
    border-radius: 30px;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 2px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: $lilac;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: $dark_blue;
    border: 1px solid $purple;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
    background-color: $purple;
  }

  input:disabled + .slider {
    cursor: not-allowed;
  }

  @include media-query('phone-wide', max) {
    width: 38px;
    height: 19px;

    .slider {
      width: 38px;
      height: 19px;
    }

    .slider:before {
      height: 13px;
      width: 13px;
    }
  }
}
