@import '../../../utils/stylesheet/colors.scss';
@import '../../../utils/stylesheet/mixins.scss';

.FAQSectionWrapper {
  background-color: $trivia_section_bg;
  color: $pure_white;
  border-radius: 0.5rem;
  padding-bottom: 4rem;
  .FAQHeaderWrapper {
    margin: 0rem 3rem 0rem 1rem;
    padding: 2rem 0rem 3rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    @include media-query('phone-wide', max) {
      margin: 0rem 1rem;
      padding: 1rem 0rem 1.5rem;
      flex-direction: column-reverse;
    }
    .haveMoreLink {
      @include font-poppins(14, $pure_white, 400, 21, normal);
      @include media-query('phone-wide', max) {
        padding-top: 1rem;
        @include font-poppins(14, $pure_white, 400, 20, normal);
      }
      .link {
        a {
          @include font-poppins(14, $purple, 600, 21, normal);
          @include media-query('phone-wide', max) {
            @include font-poppins(14, $purple, 400, 20, normal);
          }
        }
      }
    }
    .subHeading {
      @include font-montserrat(18, $pure_white, 600, 26, normal);
      @include media-query('phone-wide', max) {
        @include font-montserrat(16, $pure_white, 600, 24, normal);
      }
    }
  }

  .questionsWrapper {
    margin: 0rem 4rem;
    @include media-query('phone-wide', max) {
      margin: 0 1rem;
    }
    .faqItems {
      padding: 1.5rem;
      border-radius: 0.5rem;
      border: 0.7px solid $purple;
      margin-bottom: 1rem;
      &.openedAnswer {
        border: 2px solid $purple;
      }
      .faqQuestion {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .question {
          @include font-poppins(18, $pure_white, 500, 25.2, normal);
          @include media-query('phone-wide', max) {
            @include font-montserrat(14, $pure_white, 500, 19.6, normal);
            width: 85%;
          }
        }
        .toggleButton {
          text-align: center;
          width: 2rem;
          height: 2rem;
          background-color: transparent;
          cursor: pointer;
          border: 2px solid $purple;
          border-radius: 50%;
          &.open {
            background-color: $purple;
            width: 2rem;
            svg {
              padding-top: 0.8rem;
              path {
                stroke: white;
              }
            }
          }
          &.closed {
            transform: rotate(-90deg);
            svg {
              padding-top: 0.8rem;
            }
          }
        }
      }
      .answer {
        max-width: 85%;
        @include font-poppins(14, $pure_white, 400, 24, normal);
        @include media-query('phone-wide', max) {
          @include font-montserrat(14, $pure_white, 300, 24, normal);
          max-width: 100%;
        }
        p {
          a {
            color: $pure_white;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
