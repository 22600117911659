@import 'utils/stylesheet/colors.scss';
@import 'utils/stylesheet/mixins.scss';

.cardsContainer {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;

  @include media-query('tablet-wide', max) {
    grid-template-columns: auto;
  }
}

.teamsGrid {
  grid-template-columns: auto;
}
