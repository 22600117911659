@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.formContainer {
  @include flex(column);
  gap: 1.25rem;

  .dateAndTimezoneContainer {
    @include flex(row, space-between, center);
    gap: 16px;
    width: 100%;
  }

  .noChannelIntegratedNote {
    @include font-montserrat(11, $pure_white, 400, 15, normal);
    margin-top: -15px;
    letter-spacing: 0.5px;
  }
}
