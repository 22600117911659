@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.tag {
  border-radius: 30px;
  max-width: 80px;
  width: max-content;
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  @include font-montserrat(10, black, 500, 12, normal);
  text-transform: capitalize;
  user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pink {
  background: $tag_pink;
  color: black;
}

.blue {
  background: $tag_blue;
  color: black;
}

.grey {
  background: $grey;
  color: $lilac_white;
}

.special {
  background: transparent;
  border: 1px solid $orange;
  color: $white;
}

.red {
  background: $red;
  color: black;
}

.plan {
  background: #e4daff;
  color: #2a00a2;
}

.yellow,
.pastel-green {
  color: $dark_blue;
}
.yellow {
  background: $yellow;
}
.pastel-green {
  background: $pastel_green;
}

.popular-plan {
  background: $cornflower_blue;
  color: $pure_white;
}

.suspended-plan {
  background: $red;
  color: $pure_white;
}
