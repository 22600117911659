@import '../../../../utils/stylesheet/colors.scss';
@import '../../../../utils/stylesheet/mixins.scss';

.knowYourTeamOverviewContainer {
  padding: unset;
  position: relative;
  margin-bottom: unset;

  .headerClass {
    margin: 1rem;
  }
  .knowYourTeamAnalyticsbutton {
    @include font-poppins(14, $voilet_blue, 600, 21, 'normal');
    cursor: pointer;

    @include media-query('phone-wide', max) {
      @include font-montserrat(12, $voilet_blue, 600, 21, 'normal');
    }
  }

  .cardContentWrapper {
    @include flex(column, flex-start, flex-start);
    gap: 1rem;
    margin: 0 1rem;

    .teamOverview {
      @include flex(row, flex-start, flex-start);
      column-gap: 1rem;

      @include media-query('phone-wide', max) {
        flex-direction: column;
        row-gap: 1rem;
      }

      .teamOverviewLabels {
        flex: 1 1;
        @include flex(column, flex-start, flex-start);

        .dataNumbers {
          @include font-poppins(16, $dull_white, 600, 24, 'normal');
          @include flex(row, flex-start, center);
          column-gap: 0.4rem;
        }

        .dataText {
          margin-top: 0.3rem;
          @include font-poppins(12, $analytics_dull_white, 500, 18, 'normal');
          @include flex(row, flex-start, flex-start);
        }

        @include media-query('phone-wide', max) {
          .dataNumbers {
            @include font-poppins(16, $dull_white, 600, 24, 'normal');
          }

          .dataText {
            @include font-poppins(12, $analytics_dull_white, 500, 18, 'normal');
          }
        }
      }
    }

    .peopleIconContainer {
      line-height: 0;

      .peopleIcon {
        height: fit-content;
        width: 100%;
      }

      .reduceWidth {
        width: 153px;
      }
    }
  }

  .teamsCardContentWrapper {
    @include flex(row, space-between, center);
    width: -webkit-fill-available;

    .teamsTeamOverview {
      column-gap: 6.875rem;

      .teamsTeamOverviewLabels {
        flex: unset;
      }
    }

    @include media-query('phone-wide', max) {
      @include flex(column, flex-start, flex-start);
    }
  }

  .emptyStateFooter {
    @include flex(row, flex-start, center);
    padding: 0.75rem;
    background-color: $light_voilet;
    position: relative;
    width: -webkit-fill-available;
    border-radius: 0 0 4px 4px;

    .footerDescription {
      @include font-poppins(14, $pure_white, 500, 21, normal);
      margin-right: 7rem;
    }

    .playGamesButton {
      position: absolute;
      right: 1rem;
      @include flex(row, initial, center);
      gap: 0.5rem;
      @include font-poppins(16, $pure_white, 500, 24, normal);
      cursor: pointer;
    }

    @include media-query('phone-wide', max) {
      .footerDescription {
        @include media-query('phone', max) {
          max-width: 160px;
        }
      }

      .playGamesButton {
        right: 1rem;
        @include font-poppins(14, $pure_white, 500, 20, normal);
      }
    }
  }
}

.knowYourTeamOverviewContainer:nth-of-type(1) {
  @include font-montserrat(18, $dull_white, 600, 26, normal);
  margin: 0px;
}

.knowYourTeamAnalyticsbutton{
  @include font-poppins(14, $purple, 600, 21, normal);
  padding-right: 0.5rem;
  
}
