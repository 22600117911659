@import '../../../utils/stylesheet/mixins.scss';
@import '../../../utils/stylesheet/colors.scss';

.accessSection {
  background-color: $trivia_section_bg;
  border-radius: 4px;
  padding: 2rem;

  .header {
    gap: 12px;
    @include flex(column, flex-start, flex-start);
    margin-bottom: 1.5rem;
    @include font-montserrat(18, $white, 600, 29, normal);
  }

  .searchBar {
    width: 30%;
    border: 1px;
    margin-bottom: 1.2rem;
    @include media-query('tablet-wide', max) {
      width: auto;
    }
  }

  .switchContainer {
    display: flex;
    margin-bottom: 1rem;
  }

  .upgradePlan {
    margin-top: 1rem;
    cursor: pointer;
    @include font-poppins(14, $purple, 500, 21, normal);
    &:hover {
      text-decoration: underline;
    }
  }

  .accessWorkspaceWrapper {
    p {
      @include font-montserrat(12, $lilac, 500, 15, normal);
      margin-bottom: 4px;
    }

    .accessWorkspace {
      padding: 1.7rem 1rem 1rem;
      background: $faint_purple;
      border-radius: 4px;

      .users {
        overflow: auto;
        height: 7.5rem;

        .accessPerson {
          @include inline-flex(row, center, center);
          background: $faint_purple;
          border: 1px solid $dark_blue;
          box-sizing: border-box;
          border-radius: 30px;
          padding: 2px 12px 2px 2px;
          margin: 0 1.875rem 1rem 0;
          width: fit-content;
          cursor: pointer;
          &:last-child {
            margin: 0;
          }

          img {
            border-radius: 50%;
          }

          label {
            margin: 0 6px 0 8px;
            @include font-montserrat(14, $whitish, 500, 17, normal);
          }
          .close {
            height: 26px;
            svg {
              width: 10px;
              cursor: pointer;
            }
          }

          .closeHover {
            display: none;
          }

          &:hover {
            background: $dark_blue;
            border: 1px solid $border_blue;
            box-shadow: 0px 4px 26px $dark_shade_blue;
            border-radius: 30px;
            .close {
              display: none;
              svg {
                width: 10px;
              }
            }
            .closeHover {
              display: inline-block;
              svg {
                width: 10px;
              }
            }
          }
        }
        @include scrollbar($dark_blue);
      }
    }

    .noUserWrapper {
      @include flex(column, center, center);
      padding: 1rem;
      background: rgba(146, 119, 255, 0.1);
      border-radius: 4px;
      height: 143px;
      margin-top: 1.5rem;

      .noUsers {
        max-width: 20rem;
        @include font-montserrat(12, $lilac, 500, 15, normal);
        text-align: center;
        word-wrap: break-word;
      }
    }
  }

  .MuiAutocomplete-root {
    width: 355px;
    @include media-query('tablet-wide', max) {
      width: -webkit-fill-available;
    }
  }
}
