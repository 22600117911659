@import '../../../utils/stylesheet/mixins';
@import '../../../utils/stylesheet/colors';

.settingsModalContainer {
  padding-bottom: 1rem;
  @include flex(column, '', '');
  gap: 1.5rem;
  .rowContainer {
    @include flex(row, space-between, bottom);
    .dropdownContainer {
      width: 360px;
      margin-right: 2rem;
      @include media-query('phone-wide', max) {
        width: 230px;
        margin-right: 1rem;
      }
    }
    .actionContainer {
      @include flex-center(row);
      width: 130px;
      margin-right: 1.5rem;
      margin-bottom: -2rem;
      @include media-query('phone-wide', max) {
        margin-right: 0.5rem;
      }

      .micWrapper {
        @include flex(row);
        gap: 0.75rem;
        .speakingIndicator {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: $purple;
          @include flex-center(row);
          gap: 2px;
          .dot {
            position: relative;
            height: 4px;
            width: 4px;
            border-radius: 1.5px;
            background: $white;
          }
          .dot::before,
          .dot::after {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 1.5px;
            background: $white;
            display: inline-block;
            position: absolute;
            top: 0;
          }
          .dot::before {
            left: -6px;
          }
          .dot::after {
            left: 6px;
          }
          .speaking {
            position: relative;
            height: 4px;
            width: 4px;
            border-radius: 1.5px;
            background: $white;
            animation: dotElastic 1s infinite linear;
          }
          .speaking::before,
          .speaking::after {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 1.5px;
            background: $white;
            display: inline-block;
            position: absolute;
            top: 0;
          }
          .speaking::before {
            left: -6px;
          }
          .speaking::after {
            left: 6px;
          }
          .speaking::after,
          .speaking::before {
            animation: dotElasticSides 1s infinite linear;
          }

          @keyframes dotElasticSides {
            0% {
              transform: scale(1, 1);
            }
            25% {
              transform: scale(1, 1.5);
            }
            50% {
              transform: scale(1, 0.67);
            }
            75% {
              transform: scale(1, 1);
            }
            100% {
              transform: scale(1, 1);
            }
          }

          @keyframes dotElastic {
            0% {
              transform: scale(1, 1);
            }
            25% {
              transform: scale(1, 1);
            }
            50% {
              transform: scale(1, 3.4);
            }
            75% {
              transform: scale(1, 1);
            }
            100% {
              transform: scale(1, 1);
            }
          }
        }
      }
    }
    .videoStream,
    .userImage {
      width: 141px;
      height: 74px;
      border-radius: 4px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
      @include media-query('phone-wide', max) {
        width: 106px;
        height: 54px;
      }
    }
    .offState {
      @include flex-center();
      background-color: $vc_light_voilet;
      height: 48px;
      width: -webkit-fill-available;
      @include font-poppins(14, $vc_black, normal, 18, normal);
      text-align: center;
      padding: 0px 4px;
      @include media-query('phone-wide', max) {
        @include font-poppins(12, $vc_black, normal, 18, normal);
        height: 37px;
        padding: 4px;
      }
    }
    .playButton {
      @include flex-center();
      gap: 0.5rem;
      cursor: pointer;
    }
  }
}
