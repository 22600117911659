@import '../../../utils/stylesheet/colors.scss';
@import '../../../utils/stylesheet/mixins.scss';

.reviewSectionWrapper {
  @include media-query('phone-wide', max) {
    @include font-montserrat(14, $white2, 600, 24, normal);
  }
  @include font-montserrat(16, $white2, 600, 26, normal);
  background-color: $trivia_section_bg;
  border-radius: 0.5rem;
  padding: 0.3rem 1rem;

  .reviewSection {
    @include media-query('tablet-wide', max) {
      height: 300px;
    }
    @include media-query('phone-wide', max) {
      height: 360px;
    }
    @include font-poppins(16, $white3, 600, 24, normal);
    border: 0.5px solid $purple;
    border-radius: 20px;
    width: 100%;
    height: 315px;
    padding: 2rem 2rem 1rem;
    margin-right: 1rem;
    .comment {
      @include media-query('phone-wide', max) {
        @include font-poppins(14, $white3, 600, 20, normal);
        padding-bottom: 1rem;
        padding-top: 0px;
      }
      @include media-query('tablet-wide', max) {
        @include font-poppins(16, $white3, 600, 24, normal);
      }
      text-align: left;
      padding-top: 1rem;
      height: 190px;
      .commentTitle {
        margin: 0;
      }
      .commentText {
        @include font-montserrat(12, $pure_white, 400, 18, normal);
      }
    }
    .userInfo {
      margin-bottom: 0;
      display: flex;
      justify-content: left;
      align-items: center;
      .personImage {
        display: flex;
        flex-direction: column;
        .picture {
          padding-top: 1rem;
          img {
            @include media-query('phone-wide', max) {
              width: 40px;
              height: 40px;
            }
            height: 50px;
            width: 50px;
            border-radius: 50%;
          }
        }
      }
      .details {
        @include media-query('phone-wide', max) {
          margin-left: 0.5rem;
          padding-top: 2.5rem;
          margin-bottom: 0px;
        }
        margin: 0;
        padding: 1rem 1rem;
        .name {
          @include media-query('phone-wide', max) {
            @include font-poppins(10, $pure_white, 700, 16, normal);
            margin-bottom: 0.2rem;
          }
          padding-top: 0.5rem;
          @include font-poppins(14, $pure_white, 700, 20, normal);
          margin-bottom: 0.5rem;
        }
        .description {
          @include media-query('phone-wide', max) {
            @include font-poppins(10, $pure_white, 400, 16, normal);
          }
          @include font-poppins(14, $pure_white, 400, 20, normal);
        }
      }
      @include media-query('phone-wide', max) {
        padding-top: 1rem;
      }
    }
  }
}
