@import 'utils/stylesheet/colors.scss';
@import 'utils/stylesheet/mixins.scss';

.virtualCoffeeOverviewContainer {
  @include flex(column, flex-start, flex-start);
  row-gap: 1.5rem;
  padding: 1.25rem;
  position: relative;
  margin-bottom: unset;

  .virtualCoffeeOverviewAllMatches {
    @include font-poppins(14, $voilet_blue, 600, 21, 'normal');
    cursor: pointer;

    @include media-query('phone-wide', max) {
      @include font-montserrat(12, $voilet_blue, 600, 21, 'normal');
    }
  }

  .virtualCoffeeOverviewContent {
    @include flex(row, space-between, flex-start);
    width: 100%;

    .virtualCoffeeMatch,
    .virtualCoffeeUsers {
      @include flex(column, flex-start, flex-start);
      row-gap: 0.25rem;

      .virtualCoffeeTop {
        @include font-poppins(16, $dull_white, 600, 24, 'normal');
      }

      .virtualCoffeeChannels {
        @include font-poppins(12, $analytics_dull_white, 500, 18, 'normal');
        @include flex(row, flex-start, center);

        .virtualCoffeeChannelName {
          @include flex(row, flex-start, center);

          .dotSeparator {
            margin: 0rem 0.2rem 0rem 0.4rem;
          }
        }
      }
    }

    @include media-query('phone-wide', max) {
      @include flex(column, flex-start, flex-start);
      row-gap: 1rem;

      .virtualCoffeeMatch {
        order: 1;
      }

      .virtualCoffeeUsers {
        order: 0;
      }
    }
  }

  .engagementRateContainer {
    background-color: $analytics_light_pink;
    padding: 1rem; 
    @include flex(row, space-between, center);
    box-sizing: border-box;
    column-gap: 1rem;
    border-radius: 0.5rem;
    width: 100%;
    height: -webkit-fill-available;

    .engagementRateHeader {
      @include flex(column, space-between, baseline);
      row-gap: 0.75rem;
      flex-wrap: nowrap;
    }
    
    .lastRoundEngagement {
      @include font-montserrat(16, $trivia_section_bg, 600, 24, 'normal');
    }
    .engagementRateProgressBar{
      width: 40%;
      text-align: center;
    }

    @include media-query('phone-wide', max) {
      @include flex(column, flex-start, flex-start);
      row-gap: 0.25rem;
      padding: 0.5rem;

      .engagementRateHeader {
        width: 100%;
        @include flex(row, space-between, baseline);

        .lastRoundEngagement {
          @include font-montserrat(12, $trivia_section_bg, 600, 24, 'normal');
        }
      }

      .engagementRate {
        .comingSoonIcon {
          width: 100%;
        }
      }
    }
  }

  @include media-query('phone-wide', max) {
    row-gap: 1.25rem;
  }
}
