@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.sidebarContainer {
  min-width: 16rem;
  z-index: 10;
  background-color: $trivia_section_bg;
  overflow-x: hidden;
  transition: 0.5s;
  color: $lilac;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include media-query('tablet-wide', max) {
    width: 0;
    display: none;
  }

  .springworksBranding {
    padding: 1.25rem 0rem 1.25rem 0rem;
    border-bottom: 0.5px solid $purplish_grey;
    margin: 0rem 1rem 0rem 1rem;
    @include flex(row, center, center);
  }

  .menuItems {
    padding-left: 0.5rem;
    a {
      text-decoration: none;
      @include flex-center(column);
      justify-content: flex-start;
      margin-left: 0.25rem;
      .item {
        padding: 1rem 0 1rem 0.75rem;
        width: 100%;
        display: flex;
        cursor: pointer;
        @include font-poppins(14, $lilac, 400, 21, normal);
        @include flex(row, center, center);
        .text {
          width: 100%;
          background-color: transparent;
          @include flex(row, flex-start, center);
          margin-left: 0.5rem;
        }
        .locked {
          path {
            fill: $lilac;
          }
          margin-left: 0.5rem;
        }
        svg {
          padding: 6px 8px;
          fill-opacity: 40%;
        }

        &:hover {
          background-color: $highlight_dark_blue;
        }
      }
      .selected {
        transition: 0.3s;
        background: $light-violet;
        @include font-poppins(14, $white, 700, 21, normal);
        border-left: 1px solid $purple;

        svg {
          fill: $pure_white;
          fill-opacity: 100%;
          background: $voilet_blue;
          border-radius: 50%;
        }
      }

      .arrow {
        display: none;
        width: 0;
        height: 0;
        border-top: 1.5rem solid transparent;
        border-left: 1rem solid $dark_grey;
        border-bottom: 1.5rem solid transparent;
      }
    }
  }

  .publicSidebar {
    @include flex(column, flex-start, center);
    padding: 1rem;
    .branding {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .tryTriviaWrapper {
      padding: 1rem;
      background: $dark_grey;
      border-radius: 4px;
      margin-bottom: 1rem;
      @include flex(column, flex-start, center);
      .heading {
        @include font-roboto(14, $white, bold, 16, normal);
        text-align: center;
        margin-bottom: 1rem;
      }
      ul {
        list-style: none;
        padding-inline-start: 0;
        li {
          @include font-montserrat(12, $white, 500, 15, normal);
          margin-bottom: 8px;
        }
      }
      .availability {
        margin-top: 1rem;
        @include flex(column, flex-start, center);
        .text {
          @include font-montserrat(12, $white, normal, 15, normal);
          margin-bottom: 4px;
        }
        .platforms {
          @include flex(row, center, center);
          .platformWrapper {
            cursor: pointer;
            margin-right: 0.5rem;
            background: $white;
            border-radius: 4px;
            padding: 6px 8px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .socialLinks {
      .text {
        @include font-montserrat(10, $white, normal, 12, normal);
        margin-bottom: 0.5rem;
        text-align: center;
      }
      .socialIcons {
        @include flex(row, center, center);
        button {
          margin-right: 1rem;
          outline: none;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.avatarWrapper {
  pointer-events: none;
  .avatar {
    border-radius: 50%;
    margin-right: 1rem;
  }

  .arrow {
    content: '';
    border: solid $purple;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transition: all 0.2s ease-in;
    margin-left: 0.8rem;
  }

  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-bottom: 0.2rem;
  }

  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-bottom: 0rem;
  }
}
