@import '../../../../utils/stylesheet/colors.scss';
@import '../../../../utils/stylesheet/mixins.scss';

.container {
  @include flex(column, initial, initial);
  gap: 10px;
  .text {
    @include font-montserrat(18, $dull_white, 600, 21, 'normal');

    @include media-query('phone-wide', max) {
      @include font-montserrat(14, $dull_white, 600, 21, 'normal');
    }
  }

  .emptyList {
    @include font-montserrat(18, $dull_white, 600, 21, 'normal');
    padding: 70px 110px;
    border: 1px dashed $light_voilet;
    width: 570px;
    text-align: center;

    @include media-query('tablet', max) {
      width: 90%;
      padding: 70px 50px;
      @include font-montserrat(14, $dull_white, 600, 21, 'normal');
    }
  }

  .listContainer {
    @include flex(column, center, center);
    gap: 30px;
  }
}

.center {
  @include flex(column, center, center);
  padding: 30px;
}
