@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.title {
  @include font-montserrat(24, $white, 600, 30, normal);
  @include flex(row, initial, center);
  padding: 0;
  gap: 5px;

  @include media-query('tablet-wide', max) {
    padding-left: 1rem;
  }
}

.standingsWrapper {
  padding: 20px 26px;
  .filterParent {
    display: flex;
    align-items: center;
    width: 100%;
    .filtersSection {
      @include flex(row, space-between, center);
      width: 100%;
      gap: 1.3rem;
      align-items: flex-start;
      @include media-query('tablet-small', max) {
        @include flex(column, space-between, flex-start);
      }
      @include media-query('phone', max) {
        align-items: center;
        gap: 1rem;
      }
      .filters {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        @include media-query('phone', max) {
          flex-direction: column;
          gap: 1rem;
        }
        .channelsDropdown {
          width: 237px;
          @include media-query('tablet-wide', max) {
            width: 195px;
          }

          @include media-query('tablet', max) {
            width: 180px;
          }

          @include media-query('mui-small', max) {
            width: 155px;
          }

          @include media-query('tablet-small', max) {
            width: 220px;
          }

          @include media-query('phablet', max) {
            width: 180px;
          }

          @include media-query('phone-wide', max) {
            width: 140px;
          }

          @include media-query('phone', max) {
            width: 258px;
          }
        }
        .mobileTimespanSwitch {
          width: 260px;
          @include media-query('tablet-wide', max) {
            width: 195px;
          }

          @include media-query('tablet', max) {
            width: 180px;
          }

          @include media-query('mui-small', max) {
            width: 155px;
          }

          @include media-query('tablet-small', max) {
            width: 220px;
            height: auto !important;
          }

          @include media-query('phablet', max) {
            width: 180px;
          }

          @include media-query('phone-wide', max) {
            width: 140px;
          }

          @include media-query('phone', max) {
            width: 258px;
            margin-left: 0;
          }
        }
      }
    }
  }

  @include media-query('phone-wide', max) {
    .heading {
      display: none;
    }
    .filterParent {
      margin-left: 0;
    }
  }

  .header {
    flex-wrap: wrap;
    .searchBar .searchContainer {
      background: $highlight_dark_blue;
      border-radius: 6px;
      width: 210px !important;
      padding: 10px;
      input {
        @include font-poppins(12, $dull_white, 400, 18, normal);
        &::placeholder {
          @include font-poppins(12, $kimberly_violet, 400, 18, normal);
        }
        &:disabled {
          cursor: not-allowed;
        }
      }
      @include media-query('tablet-wide', max) {
        width: 220px !important;
      }

      @include media-query('tablet', max) {
        width: 190px !important;
      }

      @include media-query('tablet', max) {
        width: 175px !important;
      }

      @include media-query('tablet-small', max) {
        width: 201px !important;
        margin-top: 15px;
      }

      @include media-query('phablet', max) {
        width: 181px !important;
        margin-top: 15px;
      }

      @include media-query('phone-wide', max) {
        width: 151px !important;
        margin-top: 15px;
        input {
          @include font-poppins(10, $dull_white, 400, 18, normal);
          &::placeholder {
            @include font-poppins(10, $kimberly_violet, 400, 18, normal);
          }
        }
      }

      @include media-query('phone', max) {
        width: 240px !important;
        input {
          @include font-poppins(12, $dull_white, 400, 18, normal);
          &::placeholder {
            @include font-poppins(12, $kimberly_violet, 400, 18, normal);
          }
        }
      }
    }
  }
}

.heading {
  @include font-montserrat(24, $white, 600, 29, normal);
  padding-bottom: 0.5rem;
  @include flex(row, flex-start, center);
}

.standingsWrapper .header {
  margin-bottom: 1rem;
  @include flex(row, space-between, center);

  svg {
    margin: 0 4px;
  }
  .reverse {
    transform: rotateY(180deg);
  }

  @include media-query('tablet-wide', max) {
    @include font-montserrat(18, $white, bold, 22, normal);

    svg {
      margin: 0 8px;
    }
  }
}

.neverUsed {
  min-height: 20rem;
  @include flex(column, center, center);

  .heading {
    @include flex(column, flex-start, center);
    background-color: $grey;
    border-radius: 50%;
    margin-bottom: 1.25rem;
    padding: 2.25rem 1rem;
    @include font-montserrat(16, $white, bold, 20, normal);

    svg {
      margin-bottom: 8px;
    }
  }

  .message {
    @include font-montserrat(20, $orange, bold, 24, normal);
  }

  @include media-query('tablet-wide', max) {
    .message {
      text-align: center;
    }
  }
}

.noTrivia {
  min-height: 30rem;
  @include flex(column, center, center);
  svg {
    margin-bottom: 1rem;
  }
  .message {
    @include font-montserrat(20, $white, 500, 24, normal);
    margin-bottom: 2rem;
  }

  @include media-query('tablet-wide', max) {
    min-height: 12rem;
    svg {
      width: 135px;
      height: 135px;
    }

    .message {
      @include font-montserrat(20, $white, 500, 24, normal);
      text-align: center;
    }
  }
}

.leaderboardTableContainer {
  width: 100%;
  .leaderboardTable {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $grey;
    min-height: 20rem;
    .columnHeaderContainer {
      @include font-montserrat(16, $lilac, 700, 19, normal);

      @include media-query('phone-wide', max) {
        @include font-montserrat(12, $white, 500, 14, normal);
      }
    }
    .rowContainer {
      border: none;
      border-radius: none;
      padding: 0.8rem 0.25rem;
      margin-bottom: none;
    }
    .userPhoneName {
      @include flex(row, flex-start, center);
      img {
        margin-right: 8px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
      }
      div {
        word-break: break-all;
      }
    }
    @include media-query('phone-wide', max) {
      margin: 0;
    }
  }
}

.errorMessage {
  @include font-montserrat(18, $white, 500, 27, normal);
  margin-left: 16px;
  @include media-query('tablet-wide', max) {
    @include font-montserrat(12, $white, 500, 15, normal);
  }
}

.leaderboardCardContainer {
  margin: 30px;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  align-items: flex-end;
  @include media-query('tablet', max) {
    gap: 1rem;
  }
  @include media-query('tablet-small', max) {
    gap: 0.7rem;
  }
  @include media-query('phablet', max) {
    gap: 0.3rem;
  }
  .leaderboardThird,
  .leaderboardSecond {
    @include flex(column, flex-end, center);
    width: 300px;
    border-radius: 8px;
    height: 230px;
    border: 1px solid $blue;
    padding-top: 10px;
    @include media-query('tablet-wide', max) {
      margin: 0 20px;
      width: 200px;
      .leaderboardData {
        font-size: 0.65rem;
      }
    }
    @include media-query('tablet', max) {
      width: 160px;
      .leaderboardData {
        font-size: 0.9rem;
        .desktopViewText {
          display: none;
        }
      }
    }
    @include media-query('tablet-small', max) {
      width: 140px;
      .leaderboardData {
        font-size: 0.7rem;
      }
      .leaderboardName {
        font-size: 0.9rem;
      }
      svg {
        width: 70px;
        height: 80px;
      }
    }
    @include media-query('phablet', max) {
      width: 118px;
      .leaderboardData {
        font-size: 0.5rem;
      }
      .leaderboardName {
        font-size: 0.9rem;
      }
      svg {
        width: 70px;
        height: 80px;
      }
    }
  }
  .length2 {
    order: 1;
  }
  .leaderboardName {
    @include font-montserrat(16, $white, bold, 29, normal);
    margin: 5px 10px;
    text-align: center;
    word-break: break-word;
    @include media-query('phone-wide', max) {
      @include font-montserrat(12, $white, 600, 16, normal);
    }
  }
  .leaderboardFirst {
    @include flex(column, flex-end, center);
    width: 350px;
    border-radius: 8px;
    height: 280px;
    border: 1px solid $blue;

    .leaderboardData {
      margin-bottom: 15px;
    }

    @include media-query('tablet-wide', max) {
      width: 220px;
      .leaderboardData {
        font-size: 0.7rem;
      }
    }

    @include media-query('tablet', max) {
      width: 180px;
      .leaderboardData {
        font-size: 1.1rem;
        .desktopViewText {
          display: none;
        }
      }
    }

    @include media-query('tablet-small', max) {
      width: 160px;
      .leaderboardData {
        font-size: 0.8rem;
      }
      .leaderboardName {
        font-size: 0.9rem;
      }
      svg {
        width: 110px;
        height: 90px;
      }
    }

    @include media-query('phablet', max) {
      width: 130px;
      .leaderboardData {
        font-size: 0.59rem;
      }
      .leaderboardName {
        font-size: 0.9rem;
      }
      svg {
        width: 110px;
        height: 90px;
      }
    }
  }

  .leaderboardData {
    @include font-montserrat(16, $white, normal, 29, normal);
    border-top: 0.5px solid rgba(81, 104, 254, 0.4);
    padding: 20px;
    display: flex;
    div {
      text-align: center;
      color: $lilac;
      margin: 0 5px;
      div {
        @include font-montserrat(16, $white, bold, 29, normal);
      }
    }
    .desktopViewText {
      display: none;
      @media only screen and (min-width: 480px) {
        display: block;
        margin-right: 4px;
      }
    }
    @include media-query('phone-wide', max) {
      div {
        @include font-montserrat(10, $lilac, normal, 14, normal);
        div {
          @include font-montserrat(14, $lilac, 600, 24, normal);
        }
      }
    }
  }

  @include media-query('phone-wide', max) {
    margin: 15px 0px;
    gap: 0.5rem;
    .leaderboardThird,
    .leaderboardSecond {
      margin: 0px;
      width: 100px;
      height: 180px;
    }
    .leaderboardFirst {
      width: 220px;
      height: 210px;
    }

    .leaderboardData {
      margin: 5px 10px;
      padding: 5px 0px;
      padding-top: 0.75rem;
    }
  }

  @include media-query('phone', max) {
    .leaderboardFirst {
      width: 200px;
      height: 210px;
      .leaderboardData {
        font-size: 0.59rem;
      }
      .leaderboardName {
        font-size: 0.9rem;
      }
      svg {
        width: 90px;
        height: 90px;
      }
    }

    .leaderboardSecond,
    .leaderboardThird {
      @include media-query('phone', max) {
        width: 90px;
        height: 180;
        .leaderboardData {
          font-size: 0.5rem;
        }
        .leaderboardName {
          font-size: 0.9rem;
        }
        svg {
          width: 60px;
          height: 80px;
        }
      }
    }
  }
}

.leaderboardContentWrapper {
  width: 100%;
  margin: auto;
  padding-top: 30px;
  @include media-query('phone-wide', max) {
    padding-top: 20px;
  }
}
