$dark_blue: #1a1c42;
$grey: #2e3452;
$dark_grey: #2c2f5c;
$purple: #9277ff;
$grey_blue: #37386a;
$light_purple: #e0e2ff;
$lilac: #cbcce7;
$white: #fefeff;
$tag_pink: #ff77d1;
$tag_blue: #93edfc;
$blue: #5168fe;
$orange: #ffb129;
$red: #e75c62;
$green: #30af38;
$dark_green: #007f00;
$error: #ff2200;
$switch_green: #62ffaa;
$light_green: #cdffcd;
$border_blue: #2b3788;
$gold: #ffac33;
$silver: #ccd6dd;
$bronze: #ff8a3b;
$beige: #fff8f1;
$blue_shadow: #394ab7;
$disable_grey: #c6c6c6;
$whitish: #f2f2f2;
$darkest_blue: #10133e;
$border_orange: #eb7634;
$light_orange: #feecda;
$blackish: #271b05;
$purplish: #8e94ff;
$lilac_white: #f4f2ff;
$dull_white: #fefefe;
$sugar_coral: #f57575;

$highlight_dark_blue: #24275c;
$highlight_blue_light: #8e94ff;
$highlight_teal: #04cfe6;
$purple-grey: #3d3f63;
$reddish-footer-bg: #f565654d;
$pricing_table_blue: rgba(255, 255, 255, 0.04);
$cornflower_blue: #978ff1;
$disabled_white: rgba(255, 255, 255, 0.5);
$moon_grey: #cccdf7;
$melrose_violet: #b0a3ff;

$purplish_grey: rgba(146, 119, 255, 0.4);
$greenish: #03e3a4;
$purple_bg: #282a58;
$greenish_week: rgba(129, 230, 217, 0.1);
$teams_dark_black: #242424;
$teams_black: #424242;
$teams_purple: #6264a7;
$teams_danger: #c4314b;
$teams_darkmode_bg: #252423;
$greyish_white: rgba(244, 242, 255, 0.7);
$voilet_blue: #9277ff;
$light_voiled_blue: #a192f5;
$dull_grey: rgba(250, 250, 250, 0.8);
$light_pink: #ffd7e5;
$light_blue: #cce0ff;
$highlight_purple: #5d44c1;
$shade_green: #bbead3;
$shade_orange: #f8e1c0;
$whisper: #eaeaea;
$faded_white: #fef7ea;
$kimberly_violet: #6e6893;
$faint_purple: rgba(146, 119, 255, 0.1);
$dark_shade_blue: #262c6c;

// GameCenter Colors
$trivia_section_bg: #111236;
$pure_white: #ffffff;
$white2: #fafafa;
$dark_navy_blue: #36375f;
$dull_white_text: rgba(255, 255, 255, 0.8);
$light_voilet: #9277ff;
$voilet: #6a45ff;
$violet_border: rgba(146, 119, 255, 0.6);
$light_white: rgba(255, 255, 255, 0.1);
$pastel_green: #88dc81;
$yellow: #ffdf6b;
$light_grey: rgba(255, 255, 255, 0.7);
$grey_white: #fcfcfc;
$purple-blue: #888eff;
$light-violet: rgba(146, 119, 255, 0.1);
$calender-range-violet: rgba(146, 119, 255, 0.25);
$brown: rgba(218, 107, 107, 0.2);
$highlight_blue: #3e4891;

//VirtualCoffee Colors
$vc_red: #f5222d;
$vc_grey: #333333;
$vc-dark-blue: #373374;
$vc-light-blue: #7571b7;
$vc_light_grey: #f0f0f0;
$vc_background_blue: #2e3452;
$vc_light_voilet: #9da1e2;
$vc_black: #000000;

//Analytics
$analytics_dull_white: rgba(234, 234, 234, 0.7);
$analytics_disable_background: rgba(26, 28, 66, 0.9);
$analytics_light_pink: #e1dcff;

//Overview Section
$white2: #fefefe;
$dull_white2: #eaeaea;
$dull_red: #ff7777;
$white3: #ffffff;
$light_voilet2: #9d87f7;
$light_red: rgba(255, 73, 44, 0.2);
$lavendar: #9277ff;
$shadow_balck: #0000001a;
$light_grey1: #d9d9d9;
$carbon: #33333366;
$light_white1: #ffffff1a;

//CompanySection
$Lavender_Blue: #d1caff;
$Raven: #667085;

//ComparePlan
$Lavender_Grey: #b7b9d4;
$Lavender_Blue: #c6c8ff;
$Paris_M: #2b265e;
$Shamrock: #29ddb2;
$Jacksons_Purple: #3c3976;
$purple2: #9277ff;

$light_Black_Russian: #00000005;
$light_Black_Russian_1: #00000033;
$white_grey: #ffffff80;
