@import '../../../../utils/stylesheet/colors.scss';
@import '../../../../utils/stylesheet/mixins.scss';

.withoutComingSoonContainer {
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  @include flex(row, space-between, flex-start);
  flex-wrap: wrap;
  row-gap: 1.5rem;
  margin-bottom: unset;

  .stats {
    @include flex(column, flex-start, flex-start);
    flex-wrap: wrap;

    .header {
      @include font-poppins(16, $dull_white, 600, 24, 'normal');
    }

    .content {
      @include font-poppins(12, $analytics_dull_white, 500, 18, 'normal');
      @include flex(column, flex-start, flex-start);
    }
  }

  @include media-query('phone-wide', max) {
    @include flex(column, flex-start, flex-start);

    .stats {
      @include flex(column, flex-start, flex-start);

      .header {
        @include font-poppins(14, $dull_white, 600, 21, 'normal');
      }

      .content {
        @include font-poppins(12, $analytics_dull_white, 500, 18, 'normal');
        @include flex(column, flex-start, flex-start);
      }

      @include media-query('phone-wide', max) {
        row-gap: 0.25rem;
      }
    }
  }
}
