@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.analyticsContentWrapper {
  @include flex(column, initial, initial);
  padding-top: 1.2rem !important;
  gap: 1.3rem;

  .welcomeText {
    @include font-montserrat(24, $white, 600, 36, normal);
  }
}
