@import 'utils/stylesheet/colors.scss';
@import 'utils/stylesheet/mixins.scss';

:global(.mantine-Popover-dropdown) {
  padding: 0px !important;
  border: none !important;
}

.wrapper {
  width: 220px;

  @include media-query('phone-wide', max) {
    width: 150px;
  }
}

.starArrow {
  display: none !important;
}

.rangePickerRightSection {
  display: none;
}

.dateRangePickerInputWrapper {
  color: $purplish !important;
  border: 1px solid $purplish;
  background: $dark_blue;
  padding-left: 12px !important;
  width: 235px;
  margin-top: 15px;
  @include media-query('phone-wide', max) {
    width: 255px;
    margin-top: 20px;
  }

  &::placeholder {
    color: $purplish !important;
  }

  &:focus,
  &:focus-within {
    outline: none;
    border-color: $purplish !important;
  }
}

.hiddenInputWrapper {
  opacity: 0;
  position: relative;
  top: -50px;
  z-index: -999;
}

.dropdownWrapper {
  border: 1px solid $purplish;
  background: $dark_blue;
}

.mantineDatePickerArrow {
  display: none;
}

.dropDownIcon {
  right: 0 !important;
  left: unset !important;

  svg {
    @include media-query('phone-wide', max) {
      width: 10px;
      height: 10px;
    }
  }
}

.calendarHeaderLevel {
  font-size: 16px;
  color: $white;
  &:hover {
    background: none;
  }

  svg {
    color: $purplish !important;
    width: 25px;
    height: 25px;
  }
}

.calendarControls {
  color: $purplish;

  &:hover {
    background: none;
  }

  svg {
    width: 25px;
    height: 25px;
  }
}

.inRangeWrapper {
  background-color: rgba(146, 119, 255, 0.25) !important;
}

.firstInRangeItem,
.lastInRangeItem {
  color: $dull_white !important;
}

.selectedItem {
  background-color: $purplish !important;
}

.dayItem {
  color: $purple;

  &:hover {
    background: $purplish !important;
  }
}

.outsideItem {
  display: none;
}

.weekendItem {
  color: $purple !important;
}

.activeItem {
  background: $purplish !important;
}
