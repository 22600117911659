@import '../../../utils/stylesheet/mixins.scss';
@import '../../../utils/stylesheet/colors.scss';

.loginContainer {
  @include flex(column, center, center);
  background: $grey;
  height: 100vh;

  .wrapper {
    width: 70% !important;
    @include flex(row, center, center);

    cursor: pointer;
    img {
      &:hover {
        box-shadow: 0 6px 15px $purple;
      }
    }
  }

  .header {
    @include font-poppins(40, $white, 600, 60, normal);
    margin-bottom: 1.125rem;
    text-align: center;
  }

  .image {
    svg {
      width: 100%;
      height: 70%;
    }
  }

  @include media-query('tablet-wide', max) {
    @include flex(column, flex-start, center);
    padding: 5rem;
    .header {
      @include font-poppins(16, $white, 600, 24, normal);
    }

    .image {
      text-align: center;
      svg {
        width: 75%;
        height: 75%;
      }
    }

    .wrapper {
      @include flex(column, space-between, center);
    }
  }

  @include media-query('phone', max) {
    .wrapper {
      @include flex(column, space-between, center);

      .img {
        width: 300px;
      }
    }
  }
}
