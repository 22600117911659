@import '../../../../utils/stylesheet/colors.scss';
@import '../../../../utils/stylesheet/mixins.scss';

.container {
  @include flex(column, '', '');
  gap: 30px;

  .unsubscribeText {
    @include font-montserrat(16, $white, 500, 24, normal);
  }

  .heading {
    @include font-montserrat(16, $white, 500, 36, normal);
  }

  .licenseInfo {
    @include flex(row, '', center);

    .info {
      .licenseNumber {
        @include font-montserrat(18, $white, 600, 24, normal);
      }
    }

    .info:nth-child(1) {
      margin-right: 50px;
    }
  }

  .buttonContainer {
    @include flex(row, '', '');
  }
}

.centerContainer {
  @include flex(row, center, center);
}

.line {
  width: 100%;
  border-top: 0px solid;
  border-color: $disabled_white;
}

.text {
  @include font-montserrat(12, $white, 400, 24, normal);
}
