@import '../../../../utils/stylesheet/colors.scss';
@import '../../../../utils/stylesheet/mixins.scss';

.container {
  border-radius: 4px;
  height: 100vh;
}

.topHeader {
  @include flex(row, space-between, center);
  padding: 35px 24px;

  .leftContainer {
    @include flex(row, initial, initial);
    .title {
      @include font-montserrat(18, $white, 600, 24, normal);

      @include media-query('phone-wide', max) {
        @include font-montserrat(14, $white, 600, 24, normal);
      }
    }

    @include media-query('tablet-wide', max) {
      @include flex(row, space-between, center);
      margin-bottom: 20px;
    }
  }

  @include media-query('tablet-wide', max) {
    @include flex(column, initial, initial);
    padding: 16px;
    padding-bottom: 0px;
  }
}

.actions {
  @include flex(row, initial, initial);
  height: 40px;
  gap: 10px;

  @include media-query('tablet-wide', max) {
    @include flex(row, space-between, initial);
  }
  @include media-query('desktop', min) {
   width: 68%;
  }

  .select {
    @include media-query('phone-wide', max) {
      width: 50%;
    }
  }

  .search {
    max-width: 245px;
    width: -webkit-fill-available;
    @include media-query('phone-wide', max) {
      width: 50%;
    }
  }
}

.tableContainer {
  margin-bottom: 160px;
  overflow-y: scroll;
  height: 80%;
  @include scrollbar($dark_blue);
}
