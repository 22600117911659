@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.pollResultsWrapper {
    .heading { 
        padding-bottom: 1.1rem;
        @include font-montserrat(24, white, 600, 36, normal);
        @include flex(row, flex-start, center);

        svg {
          margin: 0 4px;
        }
    }
    .pollResultsContent{
        .loadMore{
            margin: auto;
        }
    }

    .pollsWrapper{
        &::-webkit-scrollbar {
            width: 3px; 
          }
        
          &::-webkit-scrollbar-track {
            background-color: #36407f;
          }
        
          &::-webkit-scrollbar-thumb {
            background-color: #2b3788;
            border-radius: 4px;
          }   
        background-color: $trivia_section_bg;
        padding: 2rem 1.5rem 1.5rem 2rem;
        border-radius: 4px;
        height: 75vh;
        overflow-x:hidden;
        .noPolls {
            @include flex-center(column);
            margin:9rem;
            .text {
              text-align: center;
              @include font-montserrat(20, $orange, bold, 24, normal);
            }
            @include media-query('phone-wide', max) {
                .text {
                    @include font-montserrat(14, $orange, bold, 16, normal);
                  }
                margin:6rem;
            }
    
        }
        .recentPolls{
            @include font-montserrat(18, $lilac, bold, 22, normal);
            padding-top: 2rem;
        }
        .pollItem{
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #2b3788;
            border-radius: 8px;
            margin: 0.5rem 0rem 1rem;  
            padding: 1.5rem; 
            .pollItemContent{
                width: 73%;
                padding-left: 1rem;
                display: flex;
                word-wrap: break-all;
                overflow-wrap: break-word;
                align-items: center;
                .pollDetails{
                    inline-size: 750px;
                    border-left:1px solid #2b3788;
                    padding-left: 1rem;
                    .opinion{
                        max-width: 80%;       
                        @include font-montserrat(14, $whitish, 600, 17, normal);
                        margin-bottom: 0.75rem;
                        @include media-query('phone-wide', max) {
                            max-width: 60%;
                            inline-size: 150px !important;
                        }
                        @include media-query('mui-small', max) {
                            inline-size: 300px !important;
                        }
                        @include media-query('tablet', max) {
                            inline-size: 500px;
                        }
                        @include media-query('desktop-wide', max) {
                            inline-size: 450px;
                        }
                        
                    }
                    .organizerInfo{
                        width: 100%;
                        @include font-montserrat(10, $white, 500, 12, normal);
                        span {
                          font-weight: bold;
                        }
                        @include media-query('phone-wide', max) {
                            inline-size: 200px;
                        }
                    }
                }
                .pollIndex{
                    padding-right: 2rem;
                    @include font-montserrat(16, $whitish, bold, 18, normal);
                }
                @include media-query('phone-wide', max) {
                    width: 90%;
                    inline-size: 250px;
                }
 
            }
            .pollStats{
                padding-right: 1.5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .participantsCount{
                    display: block;
                    padding-right: 1rem;
                    border-right:1px solid #2b3788;
                    @include font-montserrat(12, $whitish, 600, 15, normal);
                    svg {
                        margin-right: 4.5px;
                    }
                }
                .customTag{
                    background-color: $orange;
                    border-radius: 1rem;
                    margin-right: 1rem;
                    padding: 0.2rem;
                    @include font-montserrat(12, $dark_blue, 600, 14, normal);
                }
                .pollTiming{
                    display: block;
                    padding-left: 1rem;
                    @include font-montserrat(12, $whitish, normal, 15, normal);
                }
            }
            &:hover {
                box-sizing: border-box;
                box-shadow: 0px 4px 26px $dark_shade_blue;
              }

              @include media-query('phone-wide', max) {
                flex-direction: column;
                .pollItemContent{
                    padding-left: 0rem;
                    justify-content: space-evenly;
                    gap:0;
                    .pollIndex{
                        width:3rem;
                    }

                }
                .pollStats{
                    padding-top: 2rem;
                    justify-content:flex-end;
                    flex-wrap: wrap;
                    gap:1rem;
                    .participantsCount{
                        border-right: none;
                        padding-right: 0;
                        @include font-montserrat(10, $whitish, 600, 12, normal);
                    }
                    .pollTiming{
                        @include font-montserrat(10, $whitish, 600, 12, normal);
                    }
                }
              }
              @include media-query('tablet', max) {
                padding-left: 1rem;
                display: block;
                flex-direction: column;
                .pollStats{
                    justify-content: flex-start;
                    padding: 1.5rem 0rem 0rem 1rem;
                    .participantsCount{
                        border-right: none;
                    }
                }
            }
        }
    }
}
.pollInfoWrapper{
    .pollOverviewSection{
        .overviewHeading{
            @include font-montserrat(18, white, bold, 22, normal);
            padding: 0.5rem 0rem 1rem 1rem;
        }
        .overviewContent{
            display: grid;
            grid-template-columns:1fr 1fr 1fr 1fr;
            padding: 1.5rem 0;
            .overviewData{
                @include font-montserrat(14, white, 500, 16, normal);
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                gap: 0.5rem;
                .labels{
                    @include font-montserrat(12, $lilac, 600, 14, normal);
                }
        }
        @include media-query('phone-wide', max) {
            grid-template-columns: 1fr 1fr;
            gap:2rem;
            .overviewData{
                width:40%;
                @include font-montserrat(12, white, 500, 14, normal);
                .labels{
                    @include font-montserrat(11, $lilac, 600, 12, normal);
                }
        }
        .noParticipants{
            width: 80%;
        }
    }
        @include media-query('tablet-wide', max) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }
        }
        .noParticipants{
            background-color: $trivia_section_bg;
            border-radius: 4px;
            padding:1rem 0rem;
            @include media-query('phone-wide', max) {
                @include font-montserrat(14, $orange, bold, 16, normal);
            }
        }
    }
    .goBackButton{
        cursor: pointer;
        padding-bottom: 1rem;
        @include font-montserrat(14, rgb(146, 119, 255) , bold, 21, normal);
    }
    .pollInfoSection{
        padding-left: 2rem;
        .header{
            @include font-montserrat(18, white, bold, 22, normal);
            padding-bottom:1rem;

        }
        .noParticipants{
            @include flex(row, flex-start, center);
            padding:1rem 0rem;
            svg {
                width: 45px;
                height: 45px;
              }
            .text {
                @include font-montserrat(16, $orange, 500, 20, normal);
              }
        }
        .opinion{
            @include font-montserrat(14, $whitish,700, 15, normal);
            padding-bottom: 1rem;
            margin-left: 1rem;
            word-wrap: break-all;
            inline-size: 90%;
            overflow-wrap: break-word;
            span{
                @include font-montserrat(14, white, bold, 20, normal);
            }
        }
        .options{
            
            padding-top: 1rem;
            width: 50%;
            overflow-x: auto;
            cursor: pointer;
            display: flex;
           
            gap: 2rem;
            @include font-montserrat(14, white, 600, 20, normal);
            border-bottom: 1px solid $dark_shade_blue;
            .selectedOption{
                border-bottom: 3px solid $purple;
            }
            @include media-query('phone-wide', max) {
                width: 100%;
            }
        }
        .optionData{
            display: flex ;
            margin-bottom: 1rem;
            .alphabet{
                @include flex(row, space-between, center);
                @include font-montserrat(12, $whitish,700, 14, normal);
                padding:0rem 2rem 0rem 1rem;
                @include media-query('phone-wide', max) {
                    padding:0rem 1rem 0rem 0.5rem;
                }
            }
            .data{
                width: 90%;
                @include font-montserrat(12, #CBCCE7, 700, 64, normal);
                .option{
                    display: flex;
                .optionPercentage{
                    @include font-montserrat(12, #1A1C42, 700, 14, normal);
                    background: #CBCCE7;
                    padding:1rem 0.5rem;
                    margin-right: 2rem;
                    border-radius: 50%;
                    width: 2rem;
                    height: 1rem;
                    text-align: center;
                    margin-top: 0.5rem;
            }
            @include media-query('phone-wide', max) {
                flex-direction: column;
                .optionText{
                    padding-left: 0.3rem;
                  }
            }
        }
                @include media-query('phone-wide', max) {
                    width: 80%;
                }
                .optionChosenPeople{
                    @include font-montserrat(12, #CBCCE7, 600, 14, normal);
                }
            }

        }
    }
        
}

  .customItem[data-active='true'] {
    background: transparent !important;
    border: 1px solid #5168FE;
    border-bottom:1px solid #5168FE !important ;
  }


  .customControl[data-active='true']:hover {
    background-color: transparent !important;
  }
  
  .customItem{
    background-color: #2e3452;
    border-bottom: none !important;
    border-radius: 4px;
    .customLabel{
        @include flex(row, space-between, center);
        @include font-montserrat(12, #CBCCE7, 700, 64, normal);
        padding: 0.5rem 0rem;
        @include media-query('phone-wide', max) {
           line-height: 1.5rem;
        }

    }
    .customChevron{
        color: $purple;
      }
    
      .customContent{
        background-color: $trivia_section_bg;
        border-top: 0.01rem solid #2E3452;
        padding: 1.5rem 1rem;
        .participants{
            @include font-montserrat(14, white, 500, 20, normal);
    
            .participantsDetailsContent{
                @include font-montserrat(12, white, 400, 14, normal);
                padding:1rem 0.5rem;
            }
        }
        .noParticipants{
            padding: 0;
        }
      }
      
  .customControl{
    &:hover {
        background-color: #2e3452; 
      
      }
  }

}

