@import '../../../utils/stylesheet/mixins.scss';
@import '../../../utils/stylesheet/colors.scss';

.contactSection {
  background-color: $trivia_section_bg;
  border-radius: 4px;
  padding: 2rem;
  .header {
    @include font-montserrat(18, $white, 600, 29, normal);
    margin-bottom: 1rem;
  }
  .fieldsWrapper {
    @include flex(row, flex-start, center);
    flex-wrap: wrap;
    .inputWrapper {
      margin-left: 0;
      color: $blackish;

      @include media-query('phone-wide', max) {
        margin-left: unset;
        margin-right: unset;
      }
    }
    :nth-child(2) {
      margin-right: 1rem;
      margin-left: 0;
      ul {
        padding: 0.5rem 0;
      }

      @include media-query('phone-wide', max) {
        margin-left: unset;
        margin-right: unset;
      }
    }
  }

  @include media-query('tablet-wide', max) {
    .fieldsWrapper {
      .input-field {
        margin-left: 1rem;
        width: -webkit-fill-available;
        width: -moz-available;
      }
      .react-tel-input {
        width: -webkit-fill-available;
        width: -moz-available;
        margin-left: 1rem;
        input {
          width: -webkit-fill-available;
          width: -moz-available;
          margin-right: 0;
        }
      }
    }
  }
}
