@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.homeContentWrapper {
  @include flex(column, initial, initial);
  gap: 1.5rem;

  .welcomeText {
    @include font-montserrat(24, $white, 600, 36, normal);
  }

  .goBackButton {
    @include flex(row, initial, center);
    gap: 0.75rem;
    @include font-poppins(16, $white, 600, 24, normal);
    cursor: pointer;
  }
}

.blur {
  filter: blur(5px);
}
.proUpgradeButton{
  background-color: #9277FF;
  border-radius: 88px;
  margin: 1rem 0rem;
  float:right;
  padding: 0.2rem 2rem
}

.tailTrailModalTitle{
  @include font-montserrat(14, $white,700, 22, normal);
}

.tailTrailModalContent {
  @include font-montserrat(14, $white, 500, 20, normal);
  min-width:40%;
  background: #111236cc;
  color: $white;
  backdrop-filter: blur(10px); 
  box-shadow: 0 4px 8px #00000033;
  border-radius: 10px; 
  padding: 1rem; 
  p{
    span{
      @include font-montserrat(14, $white, 600, 20, normal);
    }
  }
}

.tailTrailModalHeader {
  @include font-montserrat(16, $white, 500, 20, normal);
  background: #111236cc; 
  color: $white;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 8px #00000033; 
  border-radius: 10px; 
}
