@import '../../../../utils/stylesheet/colors.scss';
@import '../../../../utils/stylesheet/mixins.scss';

.disableContainer {
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $analytics_disable_background;
  padding: 1rem 2.5rem;
  text-align: center;
  @include flex(column, center, center);
  row-gap: 0.7rem;

  .disableLabel {
    max-width: 370px;
    height: fit-content;
    flex: 9;
    @include font-poppins(12, $dull_white, 500, 21, 'normal');
    @include flex(row, center, center);
  }

  .disableButton {
    flex: 1;
    max-height: 2.5rem;
    text-transform: none !important;
    span {
      padding: 0rem 2.5rem;
      @include font-poppins(12, $dull_white, 600, 21, 'normal');
    }
  }

  @include media-query('phone-wide', max) {
    padding: 1rem 1rem;

    .disableLabel {
      flex: 0;
    }

    .disableButton {
      span {
        padding: 0rem 0.5rem;
      }

      flex: 0;
    }
  }
}
