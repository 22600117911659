@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.row {
  border: 1.5px solid rgba(146, 119, 255, 0.2);
  border-right-color: transparent;
  border-left-color: transparent;

  .rowElement {
    padding: 20px 30px;

    @include media-query('phone-wide', max) {
      padding: 20px 5px !important;
    }
  }
}

.row:hover {
  background-color: #1e204d;
}

.noHover:hover {
  background-color: unset;
}

.header {
  @include font-montserrat(10, $white !important, 500, 20, normal);
  font-size: 10px !important;
  padding: 20px 30px !important;

  @include media-query('phone-wide', max) {
    padding: 20px 5px !important;
  }
}

.content {
  @include font-montserrat(16, $white, 500, 20, normal);
  border: 1.5px solid rgba(146, 119, 255, 0.2);
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom: none;
}

.actions {
  @include flex(row, initial, initial);
  border-bottom: unset !important;
  padding: 20px 30px !important;
  gap: 10px;
  width: 60px;
  visibility: hidden;

  .action {
    border-radius: 50%;
    background-color: #9277ff;
    height: 24px;
    width: 24px;
    @include flex(row, center, center);
    cursor: pointer;
  }
}

.render {
  visibility: visible;
}
