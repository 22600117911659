@import '../../utils/stylesheet/mixins.scss';
@import '../../utils/stylesheet/colors.scss';

.layoutContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh;

  .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    width: 90%;
    position: relative;

    .content {
      padding: 1.5rem 2rem;
      overflow-x: hidden;
      background-color: $grey;
      height: 100vh;
      overflow-y: auto;
      @include scrollbar($dark_blue);

      @include media-query('tablet-wide', max) {
        padding: 0.75rem;
        @include scrollbar($dark_blue, true);
      }
    }

    .dashboardContent {
      background-image: url('../../assets/images/dashboard/dashboard-bg.png');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position-y: -64px;
      padding-top: 7rem;

      @include media-query('tablet-wide', max) {
        padding-left: 1rem;
        padding-right: 1rem;
        background-position-y:-14px
      }

      @include media-query('phone-wide', max) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        background-position-y: 0px;
      }
    }

    @include media-query('tablet-wide', max) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
    }
    .footerBanner {
      position: sticky;
      @include media-query('phone-wide', max) {
        display: none;
      }
    }
  }
}

.requestLicenseContainer {
  @include font-poppins(16, #fcfcfc, 400, 24, normal);
  padding: 1rem 0;
  .adminContainer {
    @include font-poppins(16, #fcfcfc, 400, 24, normal);
    margin-top: 36px;
    display: flex;

    .adminList {
      display: flex;

      .admin {
        text-decoration: underline;
      }
    }
  }
}

.locked {
  height: 16px;
  width: 16px;
  path {
    fill: $teams_purple;
  }
  margin-left: 0.5rem;
}

.gameContainer {
  width: 100% !important;
}
