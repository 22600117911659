@import '../../utils/stylesheet/mixins.scss';
@import '../../utils/stylesheet/colors.scss';

.customWrapper {
  .input {
    &::placeholder {
      @include font-poppins(14, $white, 400, 21, normal);
    }

    background-color: $highlight_dark_blue !important;
    border: none;
    @include font-poppins(14, $white, 400, 21, normal);
    color: white !important;
  }
}

.dropdown {
  background: $highlight_dark_blue !important;
  border: 1px solid #8e94ff;
  color: white !important;
}
.item {
  &:hover {
    background: #8e94ff33 !important;
  }
   color: white !important;
}

.item:not(:hover) {
  background: initial !important;
}

.searchAutocomplete {
  &::placeholder {
    @include font-poppins(12, $kimberly_violet, 400, 18, normal);
  }
}
