body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 10px;
  height: 18px;
}
body::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 30px;
  background-color: #271b05;
  box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
body::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
body::-webkit-scrollbar-corner {
  background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.introjs-tooltip {
  max-width: 320px;
  background: #faf5ff;
}
a {
  text-decoration: none;
}
@media only screen and (max-width: 1024px) {
  .introjs-tooltip {
    left: 20px;
  }
}

.introjs-tooltip-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.introjs-tooltip-header > h1 {
  font-family: 'Montserrat', sans-serif;
  color: #1a1c42;
  width: 100%;
}
.introjs-tooltip-header > a {
  color: #1a1c42;
}
.introjs-tooltiptext {
  font-family: 'Montserrat', sans-serif;
  color: #1a1c42;
}
.introjs-bullets > ul > li > a {
  background: #e0e2ff;
}
.introjs-bullets > ul > li > a:hover {
  background: #9277ff;
}
.introjs-bullets > ul > li > a.active {
  background: #9277ff;
}
.introjs-tooltipbuttons {
  border-color: #cbcce7;
}
.introjs-tooltipbuttons > a:active,
.introjs-tooltipbuttons > a:focus,
.introjs-tooltipbuttons > a {
  font-family: 'Montserrat', sans-serif;
  background: #9277ff;
  color: #fff;
  text-shadow: none;
  border-radius: 4px;
  border: none;
  outline: none;
}
.introjs-tooltipbuttons > a:hover {
  outline: none;
  background: #553c9a;
  color: #fff;
}
.introjs-tooltipbuttons > .introjs-disabled {
  background: #cbcce7;
  color: #000;
}
.introjs-tooltipbuttons > .introjs-disabled:hover {
  background: #cbcce7;
  color: #000;
}
.introjs-helperLayer {
  border: 1px solid #cbcce7;
}
