@import '../../../../../utils/stylesheet/colors.scss';
@import '../../../../../utils/stylesheet/mixins.scss';

.manageLicenseContainer {
  @include flex(column, '', '');
  gap: 15px;
  .overviewBuyLicensesText {
    @include font-poppins(14, #cbcce7, 600, 21, normal);
    padding-left: 0.5rem;
  }
  .buyLicensesArrowText {
    @include flex(row, '', flex-end);
    cursor: pointer;

    .buyLicensesText {
      @include font-montserrat(14, $white, 600, 24, normal);
      margin-left: 10px;
    }
  }

  .manageLicenseInfo {
    @include flex(column, '', '');
    gap: 5px;
    padding: 0px 10px;

    .questionText {
      @include font-montserrat(12, $white, 400, 24, normal);
    }

    .licenseValue {
      @include font-montserrat(10, $white, 400, 24, normal);
    }

    .validationText {
      @include font-montserrat(12, $white, 400, 24, normal);
    }

    .textField {
      background-color: $highlight_dark_blue;
      border-color: $highlight_dark_blue;
      margin: 0;
      color: $white;
    }
    .textFieldgridContainer {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 16px;
      .txt {
        @include font-montserrat(10, $white, 400, 20, normal);
      }
    }
  }

  .totalContainer {
    @include flex(row, space-between, '');
    width: 90%;
    margin-top: 10px;
    padding: 12px 18px;
    background: $purplish_grey;
    border-radius: 8px;

    .totalText {
      @include font-montserrat(16, $white, 400, 24, normal);
    }

    .totalValue {
      @include font-montserrat(16, $white, 400, 24, normal);
    }
  }

  .noteText {
    @include font-montserrat(14, $white, 400, 24, normal);
  }

  .buttonContainer {
    @include flex(row, '', '');
  }

  .customButtonContainer {
    @include flex(row, flex-end, '');
  }
}
