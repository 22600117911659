@import '../../../utils/stylesheet/colors.scss';
@import '../../../utils/stylesheet/mixins.scss';

.wrapper {
  border-radius: 5px;

  .header {
    padding: 1.25rem;

    @include media-query('phone-wide', max) {
      padding: 1rem;
    }
  }
}

.emptyStateWrapper {
  padding: 0;
}

.container {
  padding: 0px 24px 24px 24px;
  @include flex(column, initial, initial);
  gap: 14px;

  @include media-query('phone-wide', max) {
    padding: 0px 15px 15px 15px;
  }

  .emptyStateImage {
    width: 100%;
  }
}

canvas {
  @include flex(row, center, center);
  max-width: 1500px;
}
