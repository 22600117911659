@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.myRankWrapper {
  border: 1px solid $blue;
  border-radius: 4px;
  min-height: 3.75rem;
  margin-bottom: 2rem;
  .myrank-tag {
    @include font-montserrat(12, #000, 600, 15, normal);
    margin-top: -1.25em;
    margin-left: 1.5rem;
    background: $blue;
    border: 1px solid $blue;
    border-radius: 30px;
    width: max-content;
    text-align: center;
    height: fit-content;
    padding: 4px 12px;
    margin-bottom: 0.5rem;
  }
  .myrank-content {
    @include flex(row, space-between, center);
    padding: 0 0.5rem;
    .photo-text-wrapper {
      margin: auto;
      @include flex(row, center, center);
      .rank-details {
        min-width: 700px;
        .MuiGrid-container {
          align-items: center;

          .row-cell.played {
            margin-left: 15px;
            @include media-query('tablet-small', max) {
              margin-left: 0px;
            }
          }
        }
        @include font-montserrat(16, $lilac_white, bold, 20, normal);
        .user-phone-name {
          @include flex(row, flex-start, center);
          img {
            margin-right: 8px;
            border-radius: 50%;
            width: 30px;
            height: 30px;
          }
          div {
            word-break: break-all;
          }
        }

        @include media-query('tablet', max) {
          min-width: 620px;
        }

        @include media-query('mui-small', max) {
          min-width: 555px;
        }

        @include media-query('tablet-small', max) {
          min-width: 455px;
          @include font-montserrat(14, $lilac_white, 500, 16, normal);
        }

        @include media-query('phablet', max) {
          min-width: 380px;
        }

        @include media-query('phone-wide', max) {
          min-width: 290px;
          @include font-montserrat(12, $lilac_white, 500, 16, normal);
        }

        @include media-query('phone', max) {
          min-width: 250px;
          @include font-montserrat(10, $lilac_white, 500, 16, normal);
        }
      }
      .my-photo {
        @include flex(row, center, center);
        svg {
          margin-right: 4px;
          margin-left: 4px;
        }
        img {
          border-radius: 50%;
          width: 30px;
          height: 30px;
        }
        margin-right: 2.5rem;

        span {
          @include font-montserrat(16, $white, bold, 20, normal);
          text-transform: capitalize;
          margin-left: 8px;
        }
      }
      .myrank-text {
        @include flex(row, center, center);
        svg {
          margin: 0 8px;
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
            transform: scaleX(-1);
          }
        }
        @include font-montserrat(18, $white, 500, 22, normal);

        @include media-query('tablet-wide', max) {
          @include font-montserrat(12, $white, 500, 15, normal);
        }
      }
      .error-message {
        @include font-montserrat(14, $orange, bold, 17, normal);
        @include media-query('tablet-wide', max) {
          @include font-montserrat(12, $orange, bold, 15, normal);
        }
      }
    }
    .star {
      margin: auto;
      margin-bottom: 1rem;
    }
  }

  .my-stat {
    @include font-montserrat(16, $lilac, bold, 20, normal);
  }

  @include media-query('phone-wide', max) {
    margin-top: 2rem;
  }
}

.myRankWrapper.gameCenter {
  .myrank-content {
    margin-bottom: 0.8rem;
  }
  .user-tags {
    @include flex(row, space-between, center);
    padding: 0 1rem;
    .myrank-tag,
    .played-tag,
    .score-tag {
      @include font-montserrat(12, $pure_white, 600, 14, normal);
      margin-top: -1.25em;
      margin-left: 1.5rem;
      background: $voilet;
      border: 1px solid $voilet;
      border-radius: 30px;
      width: max-content;
      text-align: center;
      height: fit-content;
      padding: 4px 12px;
      margin-bottom: 0.5rem;
      @include media-query('tablet-wide', max) {
        @include font-montserrat(11, $pure_white, 600, 12, normal);
        letter-spacing: -0.01em;
        text-align: left;
        padding: 4px 8px;
      }
    }
    .right {
      @include flex(row, space-between, center);
    }
    @include media-query('tablet-wide', max) {
      .myrank-tag {
        margin-left: 0.5rem;
      }
      .hide {
        display: none;
      }
      .score-tag {
        margin-left: 0.5rem;
      }
    }
  }
}
