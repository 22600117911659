@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.textareaField {
  height: 120px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid $purple;
  min-width: 250px;
  outline: none;
  padding: 1rem;
  width: 100%;
  resize: none;
  background-color: $whitish;
  margin: 1rem;
  @include input-placeholder {
    color: $lilac;
  }

  @include font-montserrat(14, $dark_grey, 500, 17, normal);
}

.inputField {
  height: 50px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid $purple;
  min-width: 250px;
  outline: none;
  padding-left: 1rem;
  background-color: $whitish;
  margin: 1rem;
  @include input-placeholder {
    color: $lilac;
  }
  @include font-montserrat(14, $dark_grey, 500, 17, normal);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
