@import '../../../utils/stylesheet/colors.scss';
@import '../../../utils/stylesheet/mixins.scss';

.mainOverviewWrapper{
  background-color: $trivia_section_bg;
  border-radius: 4px;
  padding: 1.5rem 2rem;
}
.freeTrailWrapper {
  @include font-poppins(18, $white2, 600, 26, normal);
  .freeTrailBody {
    @include media-query('phone-wide', max) {
      display: block;
    }
    @include flex(row, space-between, center);
    padding: 0.3rem;
    @include font-poppins(16, $white2, 600, 24, normal);
    .OverviewButton {
      @include flex(column, space-between, center);
      .overviewUpgradeplanButton {
        padding: 0.7rem 3rem;
        margin-bottom: 0.7rem;
      }
      .currentPlan {
        @include media-query('phone-wide', max) {
          max-width: 70%;
          text-align: center;
        }
      }
    }
  }
  .planDetails {
    @include media-query('phone-wide', max) {
      display: block;
    }
    @include flex(row, space-between, center);
    width: 50%;
    padding: 1rem;
  }
  .planInfo {
    @include media-query('phone-wide', max) {
      padding-bottom: 1rem;
    }
    .termInfo {
      @include font-poppins(16, $white2, 400, 24, normal);
    }
  }
}

.commonOverview {
  padding: 0px;
  width: 100%;
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 1.7fr 2fr;
  @include font-poppins(16, $dull_white2, 600, 24, normal);
  @include media-query('tablet', max) {
    display: block;
    padding: 0.5rem 0rem;
   
  }
  .overviewPlan {
    border-radius: 4px;
    padding: 1rem 1rem 1rem 2rem;
    background-color: $trivia_section_bg;
    .basicMontlyStyles {
      @include font-poppins(16, $dull_white2, 400, 24, normal);
    }
    @include media-query('tablet', max) {
      padding-bottom: 1.0rem;
      margin-top: 1rem;
    }
    .planDetails{
      @include flex(row, space-between);
      margin-bottom: 1rem;
      .manageButton{
          display: flex;
          position: relative; 
        @include flex(row, space-around, left);
        .threeDots {
          transform: rotate(90deg);
          cursor: pointer;
          color: $purple  ;
          padding: 5px;
          border-radius: 5px;
        }
        .popup {
          top: 100%; 
          right: 0;
          background: $trivia_section_bg;
          border-radius: 5px;
          z-index: 10;
          white-space: nowrap; 
          position: absolute;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          .closeButton {
            cursor: pointer;
            button{
              border: 2px solid $purple;
              border-radius:5px !important;
              color:#CA4949 !important;
              padding-left: 0px !important;
            }
          }
        }

      }
    }
    .planDetailBillingSection{
      padding-right: 3rem;
      @include flex(row, space-between, center);
    }
  }
  .channelsAndUsers{
    display: flex;
    flex-direction: column;
    padding:0.5rem 2rem 1rem 2rem;
    .heading {
      padding-top: 1rem;  
    }
    .manageChannels{
      @include font-poppins(14, $purple, 400, 20, normal);
      cursor: pointer;
    }
    .channelAndUserInfo{
      width: 100%;
      display: grid;
      grid-template-columns: 2fr 1fr;
      column-gap: 4rem;
      align-self: center;
      @include media-query('phone-wide', max) {
        display: block;
      }
    }

  }
  .heading {
    @include font-poppins(18, $white2, 600, 26, normal);
    margin-bottom: 1rem;
  }
}
.nonChannelBasedOverview{
  grid-template-columns: 2.5fr 1.5fr !important;
}
.currentPlan {
  @include media-query('phone-wide', max) {
    padding-bottom: 0.7rem;
  }
  @include media-query('phone-wide', max) {
    @include font-poppins(12, $dull_white2, 400, 19.6, normal);
  }
  @include font-poppins(12, $dull_white2, 500, 18, normal);
  opacity: 0.7;
  .manageChannels {
    @include font-poppins(12, $light_voilet2, 500, 18, normal);
    opacity: 1 !important;
    cursor: pointer;
    text-decoration: underline;
  }
}
.basicRestricted {
  @include media-query('phone-wide', max) {
    padding: 0rem 0rem;
  }
  padding: 0.4rem 1rem;
  .basicOverviewWrapper {
    @include media-query('phone-wide', max) {
      display: block;
    }
    @include flex(row, space-between, center);
    position: relative;
    .infoContainer {
      @include media-query('phone-wide', max) {
        @include font-poppins(16, $white3, 600, 22.4, normal);
      }
      @include media-query('tablet-wide', max) {
        @include font-poppins(14, $white3, 600, 20, normal);
      }
      @include font-poppins(18, $white3, 600, 27, normal);
      padding-top: 0.2rem;
      .restrictedPlayers {
        position: relative;
        @include font-poppins(18, $dull_red, 600, 27, normal);
        @include media-query('phone-wide', max) {
          @include font-poppins(16, $dull_red, 600, 22.4, normal);
        }
      }
      .warning {
        @include media-query('tablet-wide', max) {
          @include font-poppins(12, $dull_red, 600, 19, normal);
        }
        @include font-poppins(14, $white2, 600, 21, normal);
        margin-top: 1rem;
        background-color: $light_white1;
        border-radius: 20px;
        padding: 0.2rem 0.8rem 0.3rem;
        margin-bottom: 0.7rem;
        opacity: 1 !important;
        width: fit-content;
        span {
          @include font-poppins(14, $white2, 400, 21, normal);
          opacity: 0.8;
        }
      }
    }
    .actionContainer {
      @include flex(column, center, center);
      .basicUpgradeplanButton {
        a {
          @include font-poppins(16, $white3, 600, 21, normal);
          padding: 0rem 1rem;
        }
      }
      .description {
        text-align: center;
        @include media-query('phone-wide', max) {
          @include font-poppins(14, $white2, 400, 20, normal);
        }
        @include media-query('tablet-wide', max) {
          @include font-poppins(12, $white2, 400, 18, normal);
          text-align: center;
        }
        margin-top: 0.5rem;
        span {
          align-items: center;
          max-width: fit-content;
          padding: 0rem 1.5rem;
        }
        @include font-poppins(16, $white2, 400, 22.4, normal);
        align-items: center;
        opacity: 0.8;
      }
    }
  }
}

.additionalNames {
  overflow-y: scroll;
  position: absolute;
  top: 0;
  right: -25%;
  background: $trivia_section_bg;
  border: 1px solid $purple;
  box-shadow: 0 0 10px $shadow_balck;
  z-index: 2;
  max-height: 10rem;
  width: 10rem;
  border-radius: 4px;
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $light_grey1;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ul {
    list-style: none;
    padding-left: 1rem;
    li {
      padding: 0.5rem;
      @include font-montserrat(12, $white3, 500, 15, normal);
    }
  }
}

.manageChannelsModal {
  margin-top: 2rem;
  @include font-montserrat(12, $white2, 500, 18, normal);
  .txt {
    padding-bottom: 0.5rem;
  }
  .note {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }
  .modalUniqueUsers {
    @include font-montserrat(14, $white2, 600, 18, normal);
  }
  .attentionMessage {
    background: $light_red;
    margin-top: 1rem;
    padding: 0.5rem;
    @include font-montserrat(12, $white2, 500, 18, normal);
    span {
      @include font-montserrat(12, $white2, 700, 18, normal);
    }
  }
}
.assignLicenseModal {
  color: white;
  .upperPart {
    @include flex(row, space-between, center);
    margin-top: 2rem;
    @include font-poppins(20, $lilac, 500, 30, normal);
    div span {
      @include font-poppins(14, $lilac, 400, 20, normal);
    }
  }
  .couldNotPlayNote {
    @include font-poppins(14, $lilac, 400, 20, normal);
  }
  .searchBar {
    margin-top: 1rem;
    background: $carbon;
    max-height: 250px; /* Adjust the height as needed */
    overflow-y: auto;
    padding: 1rem;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $light_grey1;
      border-radius: 2px;
      height: 2px;
    }
  }
}

.standardProOverviewWrapper{
  background-color: #2e3452;
  @include flex(row, initial, initial);
}
