@import '../../../utils/stylesheet/mixins.scss';
@import '../../../utils/stylesheet/colors.scss';

.installContainer {
  @include flex(column, center, center);
  background: $grey;
  height: 100vh;

  .wrapper {
    @include flex(row, space-around, center);
    &--slack {
      margin-right: 0.5rem;
    }
  }

  .header {
    @include font-poppins(40, $white, 600, 60, normal);
    margin-top: 4.75rem;
    margin-bottom: 1.125rem;
    text-align: center;
  }

  .image {
    svg {
      width: 100%;
      height: 70%;
    }
  }

  .addToSlack {
    display: flex;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 6px 15px $purple;
    }
  }

  @include media-query('tablet-wide', max) {
    @include flex(column, flex-start, center);
    padding: 5rem;
    .header {
      @include font-poppins(16, $white, 600, 24, normal);
    }

    .image {
      text-align: center;
      svg {
        width: 75%;
        height: 75%;
      }
    }

    .wrapper {
      @include flex(column, space-between, center);

      .slackWrapper {
        margin-right: 0;
        margin-bottom: 0.5rem;
      }
    }
  }
}
