@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.footerCard {
  background: $light_voilet;
  padding: 1.5rem;
  @include flex(row, space-between, center);
  box-sizing: border-box;
  gap: 3rem;
  @include media-query('tablet-wide', max) {
    gap: 0.5rem;
  }
  b {
    font-weight: 600;
  }
  .svgAndText {
    @include flex(row, space-around, center);
    gap: 1.25rem;
    .svg {
      height: 5rem;
      width: 5rem;
    }
    @include media-query('tablet-wide', max) {
      gap: 0.5rem;
      .svg {
        height: 6rem;
        width: 6rem;
      }
    }
  }
  .plans {
    @include font-poppins(14, $pure_white, 400, 20, normal);
  }
  .details {
    @include font-poppins(14, #d4d0f8, 100, 20, normal);
  }
  .buttons {
    @include flex(row, space-around, center);
    gap: 0.5rem;
    @include media-query('tablet-wide', max) {
      @include flex(column, space-between, center);
      gap: 0.25rem;
    }
    .plansBtn {
      height: 44px;
      width: 167px;
      border: 1px solid $pure_white;
      font-weight: 500;
    }
    .getStartedBtn {
      height: 44px;
      width: 157px;
      font-weight: 500;
    }
  }
}
