@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.customQuizResultsWrapper .header,
.customQuizInsights .header,
.header {
  @include font-montserrat(24, $white, 600, 29, normal);
  margin-bottom: 1.5rem;
  @include flex(row, flex-start, center);

  svg {
    margin: 0 4px;
  }
  .reverse {
    transform: rotateY(180deg);
  }

  @include media-query('tablet-wide', max) {

    svg {
      margin: 0 8px;
    }
  }
}

.customQuizInsights .noParticipants {
  @include flex(column, center, center);
  svg {
    margin-bottom: 1rem;
  }
  .text {
    text-align: center;
    @include font-montserrat(20, $orange, bold, 24, normal);
  }
}

.customQuizResultsListContainer {
  padding: 2rem 2.7rem 2rem 0.8rem;
  margin-bottom: 2rem;

  .title {
    @include font-montserrat(18, $lilac, bold, 22, normal);
    margin-bottom: 1rem;
  }
  .listItem {
    border: 1px solid $border_blue;
    align-items: center;
    border-radius: 8px;
    padding: 0.8rem 1rem;
    cursor: pointer;
    margin-bottom: 1.5rem;
    margin: none !important;
    .listItemCell {
      padding: 0rem 1rem;
      .name {
        @include font-montserrat(14, $whitish, 600, 17, normal);
        margin-bottom: 0.75rem;
      }
      .organized {
        @include font-montserrat(10, $white, 500, 12, normal);
        span {
          font-weight: bold;
        }
      }
      .stat {
        @include font-montserrat(12, $whitish, 600, 15, normal);
        @include flex(row, flex-start, center);
        svg {
          margin-right: 4.5px;
        }
      }
      .text {
        @include font-montserrat(12, $lilac, normal, 15, normal);
      }
      &:first-child {
        border-right: 1px solid $border_blue;
        @include font-montserrat(16, $white, bold, 18, normal);
        line-height: 4rem;
        padding: 0rem !important ;
        max-width: 3rem;
        @include media-query('phone-wide', max) {
          padding-right: 1rem !important;
        }
      }
      &:last-child {
        @include flex(row, flex-end, center);
        .statsWrapper {
          border-right: 1px solid $border_blue;
          padding: 1rem;
          &:last-child {
            width: 5rem;
            border: none;
            @include font-montserrat(11, $whitish, normal, 14, normal);
            text-align: right;
          }
          @include media-query('tablet-wide', max) {
           padding: 1rem 0.6rem;
           border-right: none;
          }

        }
        @include media-query('phone-wide', max) {
          min-width:100%; 
          width: 4rem;
          padding: 1rem;
          margin-top: 1rem;
          .statsWrapper {
            padding: 0 0.5rem;
            &:last-child {
              @include font-montserrat(10, $whitish, normal, 13, normal);
            }
          }
        }
      }
      @include media-query('phone-wide', max) {
        padding: 0rem 0.5rem;
      }
      @include media-query('tablet', max) {
  
        &:last-child {
          min-width: 100%;
          justify-content: flex-start;
          margin-top: 1rem;
        }
      }
    }

    &:hover {
      box-sizing: border-box;
      box-shadow: 0px 4px 26px $dark_shade_blue;
    }
    @include media-query('tablet', max) {
        justify-content: flex-start;
    }
  }
  .mostRecentResult{
    width: 100%;
    .listItem{
    margin-right: 0.2rem !important;
    margin-left: 0.1rem !important;
  }
}
  .mostRecentResult,
  .pastResults {
    margin:0rem 1.2rem 1rem;
  }

  .pastResults {
    display: flex;
    flex-direction: column;
    width: 100%;
    .resultsList {
      padding: 0.5rem;
      max-height: 55vh;
      overflow-y:scroll;
      overflow-x: hidden;
      @include scrollbar(#5168FE,false,true);
      margin-bottom: 1.5rem;
    }
    .loadMore {
      margin: 0 auto;
    }
  }

  .noCustomQuizzes {
    @include flex-center(column);
    svg {
      margin: 1.5rem 0;
    }

    .text {
      text-align: center;
      @include font-montserrat(20, $orange, bold, 24, normal);
      margin-bottom: 10rem;
    }
  }
  @include media-query('phone-wide', max) {
    width:86%;
  }
}

.quizResultContainer {
  .insightsWrapper {
    @include flex(row, space-between, unset);
    .left {
      background: $grey;
      border-radius: 4px;
      padding: 1rem;
      width: 50%;

      .columnHeader {
        * {
          @include font-montserrat(12, $white, normal, 15, normal);
        }
        margin-bottom: 1rem;
        .center {
          text-align: center;
        }
      }

      .rowsWrapper {
        max-height: 15rem;
        overflow: auto;
        @include scrollbar($dark_blue);
      }
      .MuiGrid-container {
        flex-direction: row;
      }
      .row {
        @include font-montserrat(12, $white, 500, 15, normal);
        margin-bottom: 1rem;
        .center {
          text-align: center;
        }
      }
    }
    .right {
      @include flex(column, space-between, flex-start);
      width: 50%;
      padding-left: 2rem;

      .title {
        @include font-montserrat(16, $white, 600, 20, normal);
      }

      .insights {
        width: 100%;
        background: transparent;
        .stat {
          border: 1px solid $grey;
          padding: 1rem;
          @include flex(column, center, center);
          .value {
            svg {
              margin-right: 4px;
            }
            @include font-montserrat(14, $white, 600, 17, normal);
          }
          .text {
            margin-top: 1rem;
            @include font-montserrat(12, $lilac, 600, 15, normal);
          }
        }
        .top {
          border-top: 0;
        }
        .bottom {
          border-bottom: 0;
        }
        .leftcell {
          border-left: 0;
        }
        .rightcell {
          border-right: 0;
        }
      }

      .footer {
        @include flex(column, space-between, flex-start);
        width: 100%;
        .row1,
        .row2 {
          width: 100%;
          @include flex(row, space-between, center);
          .by {
            @include font-montserrat(12, $lilac, normal, 21, normal);
            span {
              font-weight: 500;
            }
          }
          .date,
          .time {
            @include font-montserrat(12, $white, 500, 15, normal);
          }
        }
      }
    }

    @include media-query('tablet-wide', max) {
      @include flex(column, space-between, unset);
      .left,
      .right {
        width: unset;
      }

      .right {
        margin-top: 1rem;
        padding-left: 0;
      }
    }
  }
}

.customQuizResultsWrapper {
  .questionResultsContainer {
    .questionResultWrapper {
      color: white !important;
      margin-bottom: 1rem;
      .question {
        margin-bottom: 1rem;
        @include flex(row, flex-start, center);
        .slNum {
          @include font-montserrat(18, $lilac, bold, 22, normal);
          margin-right: 2rem;
          @include media-query('phone-wide', max) {
            margin-right: 0.6rem;
          }
        }
        .text {
          @include font-montserrat(16, $lilac, bold, 20, normal);
        }
      }
    }
    min-height: 20vh;
    max-height: 50vh;
    overflow-y: auto;
    @include scrollbar($grey, false , true);
    .accordiann {
      @include flex(row, flex-start, center);
      .accordianWrapper {
        width: 100%;
        padding: 0.7rem 0rem;
        @include flex(row, space-between, center);
        .accordianTop {
          @include flex(row, space-between, center);

          .alphabet {
            color: white !important;
            @include font-montserrat(12, $lilac, bold, 15, normal);
            margin-right: 1rem;
          }

          .option {
            @include flex(row, flex-start, center);
            .percent {
              @include font-montserrat(12, $dark_blue, bold, 15, normal);
              background: $lilac;
              padding: 0.875rem 0.35rem;
              text-align: center;
              border-radius: 50%;
              min-width: 2rem;
              margin-right: 0.75rem;
            }
            .optionText {
              @include font-montserrat(12, $lilac, bold, 15, normal);
              margin-right: 0.25rem;
            }
          }

          .userNames {
            padding: 1rem 0rem !important;
            span {
              @include font-montserrat(12, $lilac, 500, 15, normal);
              margin: 0 1rem 1rem 0;
            }
            @include media-query('phone-wide', max) {
              width:15%;
            }
          }
        }
      }
    }

    .footer {
      @include flex(row, space-between, center);
      padding-right: 4rem;
      padding-left: 5rem;
      p {
        svg {
          margin-right: 0.5rem;
        }
        span {
          @include font-montserrat(12, $switch_green, normal, 15, normal);
        }
      }
      div {
        @include font-montserrat(12, $lilac, 500, 15, normal);
      }
      @include media-query('phone-wide', max) {
        @include flex(column, space-between, flex-start);
        padding-left: 1.6rem;
      }
    }
  }
}

.AccordionRoot {
  width: 100%;
  border-radius: 2rem !important;
}

.accorWrapper {
  background-color: transparent !important;
}

.optionWrapper {
  @include flex(row, flex-start, center);
  display: flex;
  margin-bottom: 1rem;
  .AccordionItem {
    border: none;
    width: 66rem;
    color: white !important;
    padding: none !important;
    margin: none !important;
    background-color: #2e3452;
    @include media-query('tablet-wide', max) {
      width: 53rem;
    }
    @include media-query('tablet-small', max) {
      width:30rem;
    }
    @include media-query('tablet', max) {
      width:35rem;
    }
    @include media-query('phone-wide', max) {
      width:20rem;
    }
  }
  .alphabet {
    color: white !important;
    @include font-montserrat(12, $lilac, bold, 15, normal);
    margin: 0rem 1.5rem;
    @include media-query('phone-wide', max) {
      margin: 0rem 0.5rem;
    }
  }
}

.AccordionControl {
  border-radius: 2px;
  &:hover {
    background-color: #2e3452 !important;
  }
}
.AccordionControl[data-active='true']:hover {
  background-color: transparent !important;
}

.AccordionLabel {
  padding: 0px !important ;
}

.AccordionPanel {
  padding: 2px 1rem !important ;
  width: 66rem;
  .answeredUserName {
    width: 95% !important;
    padding-top: 1rem;
    border-top: 0.01rem solid rgba(80, 79, 79, 0.4) !important;
    @include font-montserrat(12, $lilac, 500, 15, normal);
  }
  .noUsers {
    border-top: 0.01rem solid rgba(80, 79, 79, 0.4) !important;
    width: 95% !important;
    @include flex(row, flex-start, center);
    padding-top: 1rem;
    svg {
      width: 45px;
      height: 45px;
    }
    div {
      @include font-montserrat(16, $orange, 500, 20, normal);
    }
    @include media-query('phone-wide', max) {
      width: 25% !important;
      div {
        @include font-montserrat(14, $orange, 500, 16, normal);
      }
    }
  }
}
.badgeWrapper {
  border: none;
  color: $lilac;
  background: transparent;
}
.item {
  border: none !important;
}
.AccordionChevron {
  margin-left: 4rem;
  color: $lavendar;
  @include media-query('phone-wide', max) {
    margin-left: 0rem;
  }
}

.AccordionItem[data-active='true'] {
  background-color: transparent;
  border: thin solid $border_blue !important;
  border-radius: 3px;
  padding-bottom: 1rem;
}

.AccordionPanel {
  .AccordionItem[data-active='true'] {
    background-color: transparent;
  }
}
