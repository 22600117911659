@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.cardWrapper {
  @include flex(row, space-between, initial);
  gap: 1rem;

  .kytPointsWrapper {
    @include font-montserrat(14, $whisper, 400, 20, normal);
    @include flex(column, initial, initial);
    gap: 1rem;
  }

  @include media-query('phone-wide', max) {
    @include flex(column, initial, center);
    gap: 0rem;

    .kytPointsWrapper {
      @include font-montserrat(12, $whisper, 400, 18, normal);
      gap: 0.5rem;
    }

    .kytImage {
      max-width: 200px;
    }
  }
}

.automationContentWrapper {
  @include flex(column, initial, center);
  margin-top: 3rem;
  gap: 1rem;
  p {
    @include font-poppins(14, $pure_white, 600, 21, normal);
  }

  @include media-query('phone-wide', max) {
    @include flex(column, initial, center);
    margin-top: 0.5rem;
    gap: 1rem;
    p {
      @include font-poppins(12, $pure_white, 600, 18, normal);
      text-align: center;
    }
  }
}
