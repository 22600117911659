@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.react-tel-input {
  border-radius: 8px !important;
  width: auto;
  margin: 1rem;
  .form-control {
    background: $whitish;
    border-radius: 8px !important;
    font-weight: 500;
    font-size: 14px;
    height: 50px;
    color: #26284c;
    border-right: 1px solid $purple;
    border-bottom: 1px solid $purple;
    border-top: 1px solid $purple;
    width: 250px;
    &:focus {
      color: #26284c;
    }
  }
  .dropdown {
    border-radius: 8px;
    background: $whitish;
    .country {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0px 10px;

      @include font-montserrat(16, $dark_blue, 500, 20, normal);
      .country-name {
        color: $dark_blue;
      }
    }
  }

  .selected-flag {
    border-radius: 12px;
    .open {
      border-radius: 12px;
    }
    .flag {
      .arrow {
        top: 33%;
        padding: 1px;
      }
      .arrow.up {
        transform: rotate(0deg);
        top: 0;
      }
    }
  }

  .flag-dropdown {
    background: $whitish;
    border-radius: 8px 0px 0px 8px;
    border-left: 1px solid $purple;
    border-bottom: 1px solid $purple;
    border-top: 1px solid $purple;

    &:focus {
      outline: none;
      background: $whitish;
    }

    &:hover {
      background: $whitish;
    }

    .open {
      &:focus {
        outline: none;
        background: $whitish;
      }
    }
  }
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: $whitish;
}
.react-tel-input .flag-dropdown.open {
  background: $whitish;
  border-radius: 8px 0px 0px 8px;
  border-left: 1px solid $purple;
  border-bottom: 1px solid $purple;
  border-top: 1px solid $purple;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: $whitish;
  border-radius: 10px;
}

.react-tel-input .country-list {
  @include scrollbar($dark_blue);
}
