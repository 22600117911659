@import '../../../../utils/stylesheet/colors.scss';
@import '../../../../utils/stylesheet/mixins.scss';

.assignLicensesContainer {
  .topText {
    @include flex(row, space-between);
    padding: 0 40px;
    border-bottom: 1px solid $light_purple;

    .teammates {
      @include font-montserrat(24, $white, 500, 36, normal);
    }

    .totalLicenses {
      @include font-montserrat(14, $white, 500, 21, normal);
    }

    @include media-query('phablet', max) {
      padding: 0 10px;

      .teammates {
        @include font-montserrat(20, $white, 500, 36, normal);
      }

      .totalLicenses {
        @include font-montserrat(12, $white, 500, 21, normal);
      }
    }
  }

  .filterSection {
    @include flex(row, space-between);
    margin: 50px 40px 12px 50px;

    @include media-query('desktop', max) {
      margin: 50px 40px 12px 15px;
    }

    @include media-query('tablet', max) {
      @include flex(column, space-between);
    }

    .selectDropdownItem {
      @include flex(row, '', '');
      width: 100%;
      margin-right: 20px;
    }

    .licenseStatusDropdown {
      width: 100%;
      margin-left: 20px;
    }

    .selectDropdown {
      @include flex(row);
    }

    .rightSection {
      @include flex(row);
    }
  }

  .button {
    margin-left: 15px;
  }

  .usersList {
    border-top: 1px solid $purple;
    height: 100%;
    width: 100%;

    .columnNameContainer {
      @include font-poppins(12, $white, 600, 18, normal);
      text-align: left;

      @include media-query('phablet', max) {
        text-align: center;
      }

      .columnName {
        cursor: pointer;
        padding: 15px 0;
        border-bottom: 0.5px solid $grey_blue;
      }

      .columnName:first-child {
        padding-left: 100px;

        @include media-query('desktop', max) {
          padding-left: 20px;
        }

        @include media-query('phablet', max) {
          padding-right: 30px;
        }
      }

      .columnName:nth-child(3) {
        @include media-query('desktop', max) {
          padding-left: 40%;
        }
      }

      .checkboxWrapper {
        margin-right: 40px;

        @include media-query('phablet', max) {
          margin-right: 20px;
        }
      }

      .detailText,
      .detailStatusText {
        border-bottom: 0.5px solid $grey_blue;
        padding: 20px 0;
        width: auto;
        @include font-poppins(16, $white, 500, 24, normal);
        word-break: break-word;

        @include media-query('phone-wide', max) {
          @include font-poppins(12, $white, 500, 24, normal);
        }
      }

      .detailStatus {
        @include flex(row, space-between);
        width: 170px;

        @include media-query('tablet', max) {
          width: 135px;
        }

        @include media-query('phone-wide', max) {
          width: 105px;
        }
      }

      .detailStatusText {
        @include media-query('desktop', max) {
          padding-left: 10%;
        }
      }

      .detailCheckboxText {
        border-bottom: 0.5px solid $grey_blue;
        padding: 20px 0;
        @include font-poppins(16, $white, 500, 24, normal);
        word-break: break-word;
        @include flex(row, flex);
        padding-left: 100px;

        @include media-query('desktop', max) {
          padding-left: 20px;
        }

        @include media-query('phone-wide', max) {
          padding-left: 20px;
          @include font-poppins(12, $white, 500, 24, normal);
        }
      }

      .statusText {
        color: $purple;
        background-color: $white;
        width: fit-content;
        height: fit-content;
        border-radius: 15px;
        padding: 3px 10px;

        @include media-query('desktop', max) {
          margin-right: 5px;
        }
      }

      .statusTextBackground {
        background-color: $light_green;
        color: $dark_green;
      }
    }
  }

  .paginateSection {
    @include flex(row, flex-end);
    padding: 20px 50px 0px 20px;

    .paginateText {
      @include font-poppins(12, $purplish, 600, 18, normal);
    }

    .paginateText:nth-child(2) {
      margin: 0 100px;
    }
  }

  .arrowLeft {
    margin-right: 20px;
  }

  .arrowRight {
    margin-left: 20px;
  }
}

.certificateImage {
  width: 100%;
  margin-bottom: 10px;
}

.modalText {
  @include font-poppins(16, $white, 600, 20, normal);
  margin-bottom: 10px;
}

.updatedModalText {
  @include font-poppins(16, $white, 400, 20, normal);
}

.modalPadding {
  padding: 24px 0;
}

.modalTextField {
  background-color: $highlight_dark_blue;
  border-color: $highlight_dark_blue;
  margin-left: 0;
  margin-top: 0.2rem;
  color: $white;
  width: 100%;
}

.deselectBtnContainer {
  @include flex(row, flex-end);
  margin-top: 30px;
}

.customButton {
  width: 190px;
  margin-right: 1rem;
}

.transparendBtn {
  width: fit-content;
  background-color: transparent;
  border-color: $purple;
}
.ResetButton {
  @include font-poppins(14, $lavendar, 500, 21, normal);
  margin: 1rem;
  cursor: pointer;
}
.overviewSearch {
  margin-top: 0rem !important;
  width: 100%;
}
.overviewModal {
  margin-top: 0rem !important;
  width: 93%;
}
.overviewDetailCheckboxText {
  display: flex;
  justify-content: left;
  margin-top: 1rem;
  padding: 0 1rem;
  @include font-poppins(14, $white3, 400, 20, normal);
  .overviewCheckboxWrapper {
    padding-right: 0.5rem;
  }
}
.licenseRemainingBanner{
  background-color: #FEF7EA;
  border-radius: 8px;
  padding: 1rem 2rem;
  margin:1rem 2rem;
  @include font-poppins(16, #1D1D1D, 400, 24, normal);
  span{
    @include font-poppins(16, #1D1D1D, 600, 24, normal);
  }
}