@import '../../utils/stylesheet/mixins.scss';
@import '../../utils/stylesheet/colors.scss';

.contentWrapper {
  @include font-poppins(14, $white, 400, 21, normal);
  @include flex(column, initial, initial);
  gap: 1rem;

  .names {
    font-weight: bold;
  }
}
