@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.item {
  background-color: $trivia_section_bg;
  border-color: $light_voiled_blue;
  color: $light_voiled_blue;

  &:hover {
    background-color: $light_voiled_blue;
    color: $white;
  }

  &[data-active] {
    background-color: $light_voiled_blue;
  }
}
