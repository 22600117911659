@import '../../../../utils/stylesheet/colors.scss';
@import '../../../../utils/stylesheet/mixins.scss';

.container {
  @include flex(column, '', '');
  gap: 30px;

  .bodyContainer {
    margin-top: 15px;

    .body {
      @include font-montserrat(16, $white, 500, 36, normal);
    }

    .subBody {
      @include font-montserrat(14, $white, 400, 36, normal);
    }
  }

  .buttonContainer {
    @include flex(row, '', '');
  }
}
