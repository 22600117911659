@import '../../../utils/stylesheet/colors.scss';
@import '../../../utils/stylesheet/mixins.scss';

.modalWrapper {
  background: #1a1c42;
  padding-bottom: 10px;
  .header {
    @include flex(row, space-between, flex-start);
    background: #1a1c42;

    .title {
      @include font-poppins(20, $white, 500, 28, normal);
    }

    .close {
      background-image: url('../../../assets/images/close.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent;
      border: none;
      &:hover {
        background-color: $grey;
        border: none;
      }
      &:focus-visible,
      :focus {
        box-shadow: none;
      }
      svg {
        display: none;
      }
    }
  }
  .mantineModalButton {
    float: right;
    border-radius: 0.5rem !important;
    min-width: fit-content;
    font-size: 14px;
    padding: 0.4rem 0.7rem;
    font-weight: 500;
  }
}
