@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.searchContainer {
  background: $grey;
  border-radius: 70px;
  padding: 11px 30px;
  width: 50%;
  @include flex(row, flex-start, center);

  svg {
    margin-right: 4px;
  }

  input {
    @include font-montserrat(15, $lilac, normal, 22, normal);
    background: transparent;
    outline: none;
    border: none;
    width: 100%;
    @include input-placeholder {
      color: $lilac;
    }
  }

  .small {
    @include font-montserrat(10, $lilac, normal, 22, normal);
  }
}

.smallContainer {
  padding: 8px 12px;
}

.tinyContainer {
  width: 224px !important;
  padding: 6px 10px;
  background: $dark_blue;
  border-radius: 4px;
  border: 1px solid #8085e7;
  input {
    @include font-montserrat(12, $lilac, 400, 22, normal);
  }

  @include media-query('tablet-wide', max) {
    width: 100% !important;

    &:focus {
      border: 2px solid $border_blue;
      box-shadow: 0 4px 20px $blue_shadow;
    }
  }
}

.squareContainer {
  border-radius: 4px;
  width: -webkit-fill-available !important;
  width: -moz-available !important;

  @include media-query('tablet-wide', max) {
    border-radius: 8px;
  }
}
