@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.paginationContainer {
  @include flex(row, center, center);
  margin-top: 1.5rem;
  .arrow {
    border: solid $purple;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    cursor: pointer;
  }

  .prevPage {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .nextPage {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  .pages {
    @include flex(row, center, center);
    margin-bottom: 0.2rem;
    div {
      margin: 0 1rem;
      @include font-montserrat(18, $purple, 500, 21, normal);
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    .active {
      font-weight: 800;
    }
  }
}

.rowContainer {
  border: 2px solid $grey;
  border-radius: 4px;
  padding: 1.5rem 0.25rem;
  .rowCell {
    @include font-montserrat(14, $white, 500, 17, normal);
    @include flex(row, center, center);
    @include media-query('tablet-wide', max) {
      @include font-montserrat(12, $lilac, 500, 16, normal);
    }
  }
  margin-bottom: 1rem;
}

.columnHeaderContainer {
  margin-bottom: 0.75rem;
  @include font-montserrat(14, $white, 500, 17, normal);
}
