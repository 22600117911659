@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.snackbar {
  @include flex(row, space-between, center);
  background-color: $beige;
  border: 1px solid $border_orange;
  border-radius: 4px;
  padding: 1rem 2rem;
  width: 60%;
  margin: 1rem auto;
  .left {
    @include flex-center();
    .imgcontainer {
      padding: 0.6rem;
      border-radius: 50%;
      background-color: $light_orange;
      margin-right: 0.5rem;
    }
    .text {
      @include font-poppins(14, $border_orange, 600, 21, normal);
    }

    .subtext {
      @include font-poppins(14, $border_orange, 500, 21, normal);
      margin-top: 2px;
    }
  }

  .right {
    min-width: 10rem;
    .action {
      cursor: pointer;
      @include font-poppins(14, $purple, bold, 21, normal);
      text-align: center;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include media-query('phone-wide', max) {
    padding: 1rem;
    width: 85%;
    .right {
      min-width: unset;
      .action {
        text-align: center;
      }
    }
  }
}

.info {
  background-color: #f0f8ff;
  border: 1px solid #779dbe;

  .right {
    min-width: unset;
    width: max-content;
  }
}

.textOnly {
  margin: 0;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  width: -webkit-fill-available;
  width: -moz-available;
}
