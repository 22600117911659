@import '../../utils/stylesheet/mixins.scss';
@import '../../utils/stylesheet/colors.scss';

.container {
  @include flex(row, space-between, center);
  background-color: $faded_white;
  padding: 15.5px 37px;
  border-radius: 8px;

  @include media-query('tablet-small', max) {
    @include flex(column, initial, flex-start);
    padding: 15.5px 16px;
  }

  .leftContent {
    @include flex(row, initial, center);

    @include media-query('tablet-small', max) {
      @include flex(row, initial, flex-start);
    }

    .text {
      @include font-poppins(14, $vc_black, 400, 21, normal);
      margin-left: 13px;

      @include media-query('tablet-small', max) {
        @include font-poppins(12, $vc_black, 400, 18, normal);
        margin-bottom: 10px;
      }
    }
  }
}
