@import '../../../utils/stylesheet/mixins.scss';
@import '../../../utils/stylesheet/colors.scss';

.errorContainer {
  background: $grey;
  @include flex-center(column);
  height: 100vh;
  width: 100%;

  .heading {
    @include font-poppins(40, $white, 600, 60, normal);
    text-align: center;
    margin-bottom: 2rem;
    width: 90%;
  }
  .subtitle {
    @include font-poppins(32, $white, normal, 48, normal);
    margin-bottom: 4rem;
    text-align: center;
    width: 90%;
  }
  .button {
    cursor: pointer;
    background: $purple;
    border-radius: 100px;
    padding: 1.5rem 1.25rem;
    text-align: center;
    @include font-poppins(16, $white, bold, 24, normal);
    &:hover {
      padding: 1.5rem 1.25rem;
      border: 1px solid $lilac;
      box-shadow: 0px 0.25rem 0.875rem $purple;
    }
  }

  @include media-query('tablet-wide', max) {
    .heading {
      @include font-poppins(16, $white, 600, 24, normal);
    }
    .subtitle {
      @include font-poppins(12, $white, normal, 18, normal);
    }

    .image {
      text-align: center;
      svg {
        width: 75%;
        height: 75%;
      }
    }
  }
}
