@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.sectionContainer {
  overflow: auto;
  background-color: $trivia_section_bg;
  padding-right: 1rem;
  @include media-query('tablet-wide', max) {
    justify-content: center;
    padding-right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    width: unset !important;
  }

  &::-webkit-scrollbar {
    width: 12px;
    height: 18px;
  }
  &::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 30px;
    background-color: $dark_grey;
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

.noPadding {
  padding: 0;
}

.noRightPadding {
  padding-right: 0;
}

.sectionWrapper {
  background: $trivia_section_bg;
  padding: 0.75rem 0;
  width: 100%;
  margin: 1rem 0rem;
  border-radius: 4px;
  .sectionTitleWrapperNoBorder,
  .sectionTitleWrapper {
    @include flex(row, space-between, center);
    border-bottom: 0.5px solid #244f78;
    padding: 0 1.5rem 0.5rem 1.5rem;
    .sectionTitle {
      @include font-montserrat(18, #fefefe, 600, 27, normal);
      text-transform: capitalize;
      user-select: none;
    }
    .sectionAction {
      @include font-poppins(14, $purple, 600, 21, normal);
      cursor: pointer;
      user-select: none;
      &:active {
        transform: translateY(1px);
      }
    }

    @include media-query('tablet-wide', max) {
      .sectionTitle {
        @include font-montserrat(14, #fefefe, 600, 21, normal);
      }
      .sectionAction {
        @include font-poppins(12, $purple, 600, 18, normal);
      }
    }
  }
  .sectionTitleWrapperNoBorder {
    border-bottom: none;
    display: contents;
  }

  @include media-query('tablet-wide', max) {
    padding-right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    width: -webkit-fill-available;
    width: -moz-available;
    border-radius: 8px;
    margin: 1rem 1rem 0 !important;
  }
}

.fixed {
  height: 15rem;
}

.overflow {
  @include scrollbar($dark_grey);
}
