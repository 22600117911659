@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.restrictedAccessContainer {
  @include flex(column, flex-start, center);
  padding: 2rem 0;
  margin: auto;
  .noAccessImg {
    margin-bottom: 1rem;
  }

  .messageWrapper {
    @include flex(column, flex-start, center);
    text-align: center;
    .title {
      @include font-poppins(24, $white, 600, 36, normal);
    }
    .subtitle {
      padding: 1rem 0;
      @include font-poppins(14, $white, normal, 21, normal);
    }
    margin-bottom: 1rem;
  }

  @include media-query('tablet-wide', max) {
    padding: 0;
    .noAccessImg {
      height: 250px;
      width: 250px;
    }
  }
}

.adminContainer {
  display: flex;
  @include font-poppins(16, $white, 400, 21, normal);

  .adminList {
    display: flex;
  }
}
