@import '../../../utils/stylesheet/colors.scss';
@import '../../../utils/stylesheet/mixins.scss';

.companySectionWrapper {
  background-color: $Lavender_Blue;
  margin-top: 1rem;
  border-radius: 4px;
  height: fit-content;
  padding-bottom: 1rem;
  width: 100%;
  position: relative;
  .premiumDiamond {
    @include media-query('phone-wide', max) {
      bottom: 0;
      top: initial;
    }
    position: absolute;
    top: 0;
    right: 0;
    img {
      @include media-query('phone-wide', max) {
        width: 52px;
        height: 55px;
      }
      @include media-query('tablet-wide', max) {
        width: 55px;
        height: 60px;
      }
      opacity: 0.3;
    }
  }
  .companyHeaderWrapper {
    @include media-query('phone-wide', max) {
      text-align: left;
      padding: 1rem 0 1rem 1rem;
    }
    text-align: center;
    padding-top: 2rem;
    .companyHeader {
      @include media-query('phone-wide', max) {
        @include font-montserrat(16, $Raven, 700, 22.4, normal);
      }
      @include font-montserrat(18, $Raven, 700, 27, normal);
      padding-bottom: 20px;
    }
    .companyLogosWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;
      padding: 0 2rem;
      @include media-query('phone-wide', max) {
        padding-left: 0.5rem;
      }
      .Logos {
        @include media-query('phone-wide', max) {
          height: 30px;
          width: 50px;
        }
        @include media-query('tablet-wide', max) {
          height: 40px;
          width: 70px;
        }
        padding: 0 0.5rem 1rem 0;
        height: 57px;
        width: 100px;
        border-radius: 0;
      }
      .doorDash {
        @include media-query('phone-wide', max) {
          width: 120px;
        }
        @include media-query('tablet-wide', max) {
          width: 140px;
        }
        width: 150px;
      }
      .quora {
        @include media-query('phone-wide', max) {
          width: 80px;
        }
        @include media-query('tablet-wide', max) {
          width: 100px;
        }
        width: 120px;
      }
    }
  }
}
