@import '../../utils/stylesheet/colors.scss';
@import '../../utils/stylesheet/mixins.scss';

.header {
  @include font-montserrat(24, $white, 600, 29, normal);
  margin-bottom: 1.5rem;
  @include flex(row, flex-start, center);

  svg {
    margin: 0 4px;
  }
  .reverse {
    transform: rotateY(180deg);
  }

  @include media-query('tablet-wide', max) {
    
    svg {
      margin: 0 8px;
    }
  }
}

.customQuizSection {
  overflow: unset !important;
  padding: 2.5rem 2rem 2rem; 

  .noCustomQuizzes {
    @include flex-center(column);
    svg {
      margin: 1.5rem 0;
    }

    .text {
      text-align: center;
      @include font-montserrat(20, $orange, bold, 24, normal);
      margin: 1.25rem 0;
    }
    .noQuizCta {
      text-transform: none;
    }
  }

  .customQuizzesWrapper {
    .createQuizContainer {
      @include flex-center(column);
      padding: 1.25rem 0;
      border: 1px dashed $purple;
      border-radius: 4px;
      margin-bottom: 3rem;
    }

    .customQuizListContainer {
      .customQuizList {
        @include flex(column, flex-start, unset);
        min-height: 30vh;
        max-height: 50vh;
        overflow: auto;
        @include scrollbar($grey);
        margin-bottom: 3rem;
        .quizContainer {
          padding: 1.5rem 1.25rem;
          border: 1px solid $border_blue;
          border-radius: 4px;
          margin: 0.5rem 0;
          @include flex(row, space-between, center);

          .left {
            .quizName {
              @include font-montserrat(16, $white, 600, 17, normal);
              @include flex(row, flex-start, center);
              margin-bottom: 0.75rem;
              div {
                margin-left: 0.5rem;
              }
            }
            .createdBy {
              @include font-montserrat(10, $white, 500, 12, normal);
              span {
                font-weight: 800;
              }
            }
          }

          .right {
            @include flex(column, initial, flex-end);

            .top {
              @include flex(row, flex-end, center);
              margin-bottom: 0.75rem;
              width: 180px;
              * {
                padding: 0 0.5rem;
                border-right: 1px solid $border_blue;
              }
              svg {
                width: 0.75rem;
                height: 0.75rem;
                cursor: pointer;
              }
              :first-child {
                padding-left: 0;
              }
              :last-child {
                border: 0;
                padding-right: 0;
              }
            }
            .bottom {
              @include flex(row, center, center);
              * {
                padding: 0 0.5rem;
              }
              .label {
                text-align: right;
                @include font-montserrat(10, #616161, 500, 12, normal);
                margin-right: 0.5rem;
              }
              .dateTime {
                @include flex(row, space-around, center);
                text-align: left;
                .time {
                  @include font-montserrat(10, $white, 500, 12, normal);
                  border-right: 1px solid $border_blue;
                  padding-left: 0;
                }
                .date {
                  @include font-montserrat(10, $white, 500, 12, normal);
                }
              }
              .channel {
                @include font-montserrat(10, $white, 500, 12, normal);
              }
            }
          }

          &:hover {
            box-sizing: border-box;
            box-shadow: 0px 4px 26px $blue_shadow;
          }

          @include media-query('tablet-wide', max) {
            flex-direction: column;
            align-items: flex-start;

            .left {
              margin-bottom: 1rem;
            }
            .right {
              .top {
                justify-content: flex-start;
              }
              .bottom {
                * {
                  padding: 0;
                }
                .dateTime {
                  margin-bottom: 0.25rem;
                }
                .time {
                  padding-right: 0.25rem;
                }
                .date {
                  padding-left: 0.25rem;
                }
                @include flex(column, flex-start, flex-start);
              }
            }
          }
        }

        @include media-query('tablet-wide', max) {
          max-height: unset;
        }
      }
      .listFooter {
        padding: 1rem 0 0 0;
        border-top: 1px solid $grey;
        @include flex(row, flex-start, center);
        @include font-montserrat(12, $white, normal, 15, normal);
        .note {
          span {
            @include font-size(14);
            @include line-height(17);
            font-weight: 600;
          }
          div {
            margin: 0 0.25rem;
            display: inline;
          }
        }
        .bolder {
          font-weight: 600;
          margin-right: 0.25rem;
        }
        .normal {
          font-weight: 400;
        }

        @include media-query('tablet-wide', max) {
          @include flex(column, flex-start, flex-start);

          .note {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}

.createQuizModalContent {
  @include flex(row, flex-start, flex-start);
  .nameContainer {
    width: 100%;
    label {
      padding-bottom: 1rem;
    }
  }
  .nameContainer,
  .questionsCountContainer {
    @include flex(column, flex-start, flex-start);
    label {
      @include font-montserrat(14, $white, bold, 14, normal);
    }
    input {
      width: -webkit-fill-available;
      width: -moz-available;
      margin-top: 0.25rem;
      margin-left: 0;
    }
  }

  @include media-query('phone-wide', max) {
    @include flex(column, flex-start, flex-start);
  }
}

.questionsContainer {
  .status {
    @include font-montserrat(14, $white, 500, 17, normal);
    margin-bottom: 1rem;
  }

  .questionsList {
    max-height: 500px;
    overflow-y: auto;
    .question {
      margin-bottom: 1rem;
      margin-right: 5px;
      padding: 1rem 1.25rem;
      @include font-montserrat(14, $white, 500, 17, normal);
      border: 1px solid $purple;
      cursor: pointer;
      border-radius: 100px;
      word-break: break-all;
      &:hover {
        border: 1px solid $border_blue;
        box-shadow: 0px 4px 20px $blue_shadow;
        font-weight: bold;
      }
    }
    :last-child {
      margin-bottom: 0;
    }
  }
}

.addQuestionContainer {
  .correctOption .label,
  .options .label,
  .question .label {
    @include font-montserrat(14, $white, bold, 17, normal);
    margin-bottom: 0.5rem;
  }
  .question {
    textarea {
      margin: 0;
      width: -webkit-fill-available;
      width: -moz-available;
    }
    margin-bottom: 0.5rem;
  }
  .options {
    @include flex(column, flex-start, unset);
    margin-bottom: 1.5rem;
    input {
      margin: 0 0 0.5rem 0;
      width: -webkit-fill-available;
      width: -moz-available;
    }
  }
  .correctOption {
    .radioLabel {
      @include font-montserrat(14, $white, 600, 17, normal);
      label {
        color: $white !important;
      }
    }
  }
}

.scheduleContainer {
  height: fit-content;
  padding-bottom: 2rem;
  .label {
    @include font-montserrat(14, $white, 700, 17, normal);
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .pickerWrapper {
    background: $white;
    border: 1px solid #888eff;
    border-radius: 8px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5rem;
  }
  .customInput{
    padding: 1.5rem 0.8rem;
  }
  .root{
    cursor: pointer;
  }
}

.manageScheduleContainer {
  .headingContainer {
    @include flex(row, space-between, unset);
    .label {
      @include font-montserrat(16, $white, 500, 20, normal);
      text-align: left;
    }
    margin-bottom: 3rem;
  }

  .schedulesListContainer {
    .tableHeader {
      border-bottom: 1px solid $grey;
      padding: 0 1.5rem 0.75rem 1.5rem;
      margin-bottom: 0.5rem;
      @include font-montserrat(14, $white, 400, 17, normal);
    }

    .schedulesList {
      .tableRow {
        padding: 0.75rem 1.5rem;
        .cell {
          @include font-montserrat(14, $white, 600, 17, normal);
          padding-right: 0.25rem;
        }
        &:hover {
          background: #394ab5;
        }
        :last-child {
          @include flex(row, space-between, unset);
          svg {
            cursor: pointer;
          }
        }
      }

      .noSchedules {
        @include flex(column, flex-start, center);
        svg {
          margin-bottom: 1rem;
        }
        .text {
          @include font-montserrat(14, $orange, 700, 17, normal);
        }
      }
    }

    @include media-query('phone-wide', max) {
      .tableHeader {
        padding: 0;
        padding-bottom: 0.5rem;
        @include font-montserrat(12, $white, 400, 15, normal);
      }

      .schedulesList {
        .tableRow {
          padding: 0.75rem 0;
          .cell {
            @include font-montserrat(11, $white, 600, 15, normal);
            padding: 0.1rem;
          }
        }
      }
    }
  }
}

.search .wrapper:first-child svg {
  display: none !important;
}

.autocompleteWrapper {
  display: grid;
  grid-template-columns: 0.5fr 5fr;
  gap: 0;
  background-color: white;
  border-radius: 8px;
  height: 3rem;
}

.autocompleteSearch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  height: 3rem;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}

.autocompletePlaceholder {
  background-color: transparent;
  &:hover {
    box-shadow: none !important;
  }
}

.createQuizModalContent {
  @include flex(row, flex-start, flex-start);
  .nameContainer {
    width: 100%;
  }
  .nameContainer,
  .questionsCountContainer {
    @include flex(column, center, flex-start);
    label {
      @include font-montserrat(14, $white, bold, 14, normal);
    }
    input {
      width: -webkit-fill-available;
      width: -moz-available;
      margin-left: 0.25rem;
      margin-right: 0;
    }
  }
  .questionsCountContainer {
    margin-left: 1rem;
    width: fit-content !important;
    label {
      margin-bottom: 1rem;
    }
  }
  @include media-query('phone-wide', max) {
    @include flex(column, flex-start, flex-start);
  }
}

.questionsContainer {
  .status {
    @include font-montserrat(14, $white, 500, 17, normal);
    margin-bottom: 1rem;
  }

  .questionsList {
    .question {
      margin-bottom: 1rem;
      padding: 1rem 1.25rem;
      @include font-montserrat(14, $white, 500, 17, normal);
      border: 1px solid $purple;
      cursor: pointer;
      border-radius: 100px;
      word-break: break-all;
      &:hover {
        border: 1px solid $border_blue;
        box-shadow: 0px 4px 20px $blue_shadow;
        font-weight: bold;
      }
    }
    :last-child {
      margin-bottom: 0;
    }
  }
}

.addQuestionContainer {
  .correctOption .label,
  .options .label,
  .question .label {
    @include font-montserrat(14, $white, bold, 17, normal);
    margin-bottom: 0.5rem;
  }
  .question {
    textarea {
      margin: 0;
      width: -webkit-fill-available;
      width: -moz-available;
    }
    margin-bottom: 0.5rem;
  }
  .options {
    @include flex(column, flex-start, unset);
    margin-bottom: 1.5rem;
    input {
      margin: 0 0 0.5rem 0;
      width: -webkit-fill-available;
      width: -moz-available;
    }
  }
  .correctOption {
    .radioLabel {
      @include font-montserrat(14, $white, 600, 17, normal);
      label {
        color: $white !important;
      }
    }
  }
}

.scheduleContainer {
  .label {
    @include font-montserrat(14, $white, 700, 17, normal);
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .pickerWrapper {
    color: $vc_black;
    background: $white;
    border: 1px solid #888eff;
    border-radius: 8px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5rem;
  }
}

.manageScheduleContainer {
  .headingContainer {
    @include flex(row, space-between, unset);
    .label {
      @include font-montserrat(16, $white, 500, 20, normal);
      text-align: left;
    }
    margin-bottom: 3rem;
  }

  .schedulesListContainer {
    .tableHeader {
      border-bottom: 1px solid $grey;
      padding: 0 1.5rem 0.75rem 1.5rem;
      margin-bottom: 0.5rem;
      @include font-montserrat(14, $white, 400, 17, normal);
    }

    .schedulesList {
      .tableRow {
        padding: 0.75rem 1.5rem;
        .cell {
          @include font-montserrat(14, $white, 600, 17, normal);
          padding-right: 0.25rem;
        }
        &:hover {
          background: #394ab5;
        }
        :last-child {
          @include flex(row, space-between, unset);
          svg {
            cursor: pointer;
          }
        }
      }

      .noSchedules {
        @include flex(column, flex-start, center);
        svg {
          margin-bottom: 1rem;
        }
        .text {
          @include font-montserrat(14, $orange, 700, 17, normal);
        }
      }
    }

    @include media-query('phone-wide', max) {
      .tableHeader {
        padding: 0;
        padding-bottom: 0.5rem;
        @include font-montserrat(12, $white, 400, 15, normal);
      }

      .schedulesList {
        .tableRow {
          padding: 0.75rem 0;
          .cell {
            @include font-montserrat(11, $white, 600, 15, normal);
            padding: 0.1rem;
          }
        }
      }
    }
  }
}

.input {
  @include font-montserrat(14, black, 500, 16, normal);
  border: 1px solid #888eff !important;
  background: white;
  border-radius: 8px;
  min-height: 50px;
}

.timeInput {
  padding-top: 0.3rem;
}

.modalWrapper {
  background: #1a1c42;
  width: calc(100% - 50rem);
  position: fixed;
  border-radius: 4px;
  color: $white !important;

  @include media-query("tablet", max) {
    max-width: 100%;
    width: 100%;
  }

  .header {
    @include flex(row, space-between, flex-start);
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $grey !important;
    .title {
      @include font-montserrat(16, $white, 600, 20, normal);
    }

    .close {
      background-image: url("../../assets/images/close.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent;
      border: none;
      &:hover {
        background-color: $grey;
        border: none;
      }
      &:focus-visible,
      :focus {
        box-shadow: none;
      }
      svg {
        display: none;
      }
    }
  }
  @include media-query('phone-wide', max) {
        width: 100% !important;
      }
      @include media-query('desktop',max){
        width: calc(100% - 30rem) !important;
      }
      @include media-query('desktop-wide',max){
        width: calc(100% - 38rem) !important;
      }
      @include media-query('tablet-wide', max) {
        min-width: 100%;
      }
}

.customTimePicker {
  padding: 0rem 1rem;
  width: 88%;
  align-items: center;
  @include font-montserrat(14, black, 500, 16, normal);
  border: 1px solid #888eff !important;
  background: white;
  border-radius: 8px;
  height: 3.1rem;
  div {
    border: none;
  }
}

.customSelectRoot {
  max-width: 7rem !important;
  border: none !important;
}
.customSelectInput {
  height: 50px !important;
  background: $highlight_dark_blue !important ;
  @include font-montserrat(14, $white, bold, 14, normal);
  border: none !important;
}
.customSelectItem {
  background: $light_Black_Russian !important;
  color: $vc_black !important;
  &:hover {
    background: $light_Black_Russian_1 !important;
  }
}

.cell {
  cursor: context-menu;
  &:hover {
    background-color: none !important;
  }
}
.cell[data-selected] {
  border-radius: 50% !important;
  background-color: #888eff;
}

.timePickerRoot {
  width: 100%;
  border: 1px solid #888eff !important;
}
.timePickerWrapper {
  width: 100%;
}
.timeInputRoot {
  border: 1px solid #888eff !important;
  border-radius: 8px;
}
.timeInputInput {
  cursor: pointer;
  @include font-montserrat(14, Black, 500, 24, normal);
}
.timeInputSection {
  button {
    border: 2px solid black;
  }
}
.timeDropDown {
  border: 2px solid black !important;
}

.customHeaderClass {
  border-bottom: 1px solid $grey !important;
  padding-bottom: 1rem;
}