@import '../../utils/stylesheet/mixins.scss';
@import '../../utils/stylesheet/colors.scss';

.input {
  background-color: transparent;
  border-color: $purple;
}

.input:checked {
  background-color: $purple;
  border-color: $purple;
}
