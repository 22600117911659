@import '../../utils/stylesheet/mixins.scss';
@import '../../utils/stylesheet/colors.scss';

.dropdown {
  background: $dark_blue;
  border-color: $purplish;
}

.item {
  color: $white;
}

.item:hover {
  background: unset;
}
