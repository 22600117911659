@import '../../../../../utils/stylesheet/colors.scss';
@import '../../../../../utils/stylesheet/mixins.scss';

.container {
  @include flex(row, space-between, center);
  padding: 16px 0px;
  margin: 0px 16px;
  border-bottom: 1px solid rgba(146, 119, 255, 0.2);

  .detailContainer {
    @include flex(column, initial, initial);
    gap: 5px;

    .topDetail {
      @include flex(row, initial, center);
      @include font-montserrat(12, $white, 400, 24, normal);

      .gameType {
        @include font-montserrat(14, $white, 600, 21, normal);
      }
    }

    .bottomDetail {
      @include flex(row, initial, center);
      @include font-montserrat(12, $lilac, 400, 24, normal);
    }
  }
}
