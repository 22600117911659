@import '../../utils/stylesheet/mixins.scss';
@import '../../utils/stylesheet/colors.scss';

.modalWrapper {
  @include flex-center();
  position: absolute;
  z-index: 1;
  left: 0;
  top: 64px;
  width: 100%;
  height: -webkit-fill-available;
  background: linear-gradient(
    180deg,
    rgba(17, 18, 54, 0.9) 0%,
    rgba(17, 18, 54, 0.83) 31.25%,
    #111236 100%
  );

  @include media-query('tablet-wide', max) {
    top: 74px;
  }

  .modalContent {
    @include flex(column, center, center);
    gap: 20px;

    .text {
      text-align: center;
      @include font-poppins(22, $grey_white, 500, 33, normal);
    }
    .subText {
      @include font-poppins(14, $grey_white, 400, 21, normal);
      text-align: center;
    }

    .button {
      text-transform: none !important;
      border-radius: '8px';
    }
  }
}
