@import '../../../utils/stylesheet/colors.scss';
@import '../../../utils/stylesheet/mixins.scss';

.knowYourTeamContentWrapper{
    @include font-poppins(16, $white, 600, 24, normal);
    .heading{
      padding: 0.5rem 0rem;
      @include font-montserrat(24, $dull_white, 600, 36, normal);
    }
    .topContainer{
      margin-top: 1rem;
      padding:0.5rem 1rem;
      .knowYourTeamAnalyticsHeader{
        @include font-montserrat(18, $dull_white, 600, 26, normal);
        display: flex;
        justify-content: space-between;
        padding: 1rem;
      }
      .overviewWrapper{
        margin: 1rem;
        padding-top:1rem;
        .gridRoot{
          justify-content: space-around;
        }
        .analyticsItem{
            display: flex;
            gap:1rem;
            .value{
                @include font-poppins(20, $white, 600, 30, normal);
            }
            .label{
                @include font-poppins(12, $whisper, 500, 18, normal);
            }
        }
        @include media-query('phone-wide', max) {
          display: flex;
          flex-wrap: wrap;
        }

      }
    }
    .knowYourTeamContentInsights{
        width: 100%;
        .heading{
            @include font-montserrat(18, $dull_white, 600, 26, normal);
        }
        .knowyourTeamDataContent{
          display: flex;
          .knowyourTeamDataQuestions{
            padding-right: 2rem;
            width:50%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            .knowyourTeamDataCount{
              display: flex;
              gap: 1rem;
              border-bottom: 1px solid $whisper;
            }
          }
          .questionDetails{
            .userDetails{
              display: flex;
              gap:10rem;
            }
          }
          .questionsContent{
            padding: 0.5rem 0rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            .questionList{
              cursor: pointer;
              padding: 0.7rem 1rem;
              background-color: $Paris_M;
              border-radius: 8px;
              @include font-poppins(14, $whisper, 500, 21, normal);
              &.selectedQuestion{
                @include font-poppins(14, $pure_white, 500, 21, normal);
                background-color: $purple;
                @include media-query('phone-wide', max) {
                  background-color: $Paris_M;
                }
                @include media-query('tablet-wide', max) {
                  background-color: $Paris_M;
                }
              }
              .responsesCount{
                padding: 0.5rem 0 0 0.5rem;
                @include font-poppins(12, #9277FF, 400, 16, normal);
              }
            }
          }
          .questionDetails{
            max-height: 300px;
            overflow-y: auto;
            margin: 0rem 2rem;
            background-color: $Paris_M;
            width: 30rem;
            padding: 1rem;
            border-radius: 10px;
            .selectedQuestion{
              @include font-poppins(16, $pure_white, 500, 24, normal);
            }
            .responseDetailsCount{
              @include font-poppins(14, $pure_white, 600, 20, normal);
              border-bottom:2px solid $purple;
              width: fit-content;
              margin: 1rem 0;
            }
            .selectedQuestionResults{
              .userDetails{
              @include font-poppins(14, $pure_white, 400, 21, normal);
              border-bottom: 1px solid $lavendar !important;
              padding: 0.5rem 0;
              div{
                width:7rem;
              }
              }
            }
          }
          @include media-query('tablet-wide', max) {
            flex-direction: column;
            .knowyourTeamDataQuestions{
              width: 100%;
            }
            .questionDetails{
              margin: 0;
              width: 93%;
              border: 2px solid $white
            }
          }
          @include media-query('phone-wide', max) {
            .questionDetails{
              z-index: 2;
              margin: 0;
              padding: 0;
              width: 100%;
              border: 2px solid $white
            }
          }
        }

    }
  .knowyourTeamDataCount{
    @include font-montserrat(14, $dull_white, 400, 21, normal);
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    width: 40%;
    padding-top: 0.5rem;
    border-bottom: 0.5px solid $purple;
    .button{
      cursor:pointer;
      &.selected{
        border-bottom: 2px solid $purple;
      }
    }
    @include media-query('tablet-wide', max) {
      width: 100%;
    }
    @include media-query('phone-wide', max) {
      width: 100%;
    }

 }

.emptyKnowYourTeamSection{
  padding: 7rem 0rem;
  background-color:$trivia_section_bg;
  border-radius: 10px;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  Button{
    margin: 2rem 0rem 0rem 6rem;
  }
}

.knowyourTeamDataQuestions::-webkit-scrollbar {
  width: 2px;
}

.knowyourTeamDataQuestions::-webkit-scrollbar-thumb {
  background-color: $purple; 
  border-radius: 5px; 
}

.knowyourTeamDataQuestions::-webkit-scrollbar-track {
  background-color: $Paris_M;
}

.knowyourTeamDataUsersContent{
  display: flex;
  .knowyourTeamDataUsers{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    @include font-poppins(14, $whisper, 500, 21, normal);
    .usersContent{
      display: flex;
      margin-top: 0.5rem;
      flex-direction: column;
      gap: 0.5rem;
      justify-content: center;
      .user{
        background-color:$Paris_M ;
        border-radius: 10px;
        padding: 0.7rem 1rem;
      &.selectedUser{
        background-color: $purple;
        @include media-query('phone-wide', max) {
          background-color: $Paris_M;
        }
        @include media-query('tablet-wide', max) {
          background-color: $Paris_M;
        }
      }
      @include media-query('phone-wide', max) {
       position: relative;
       span{
        position: absolute;
        right: 1rem;
       }
      }
      @include media-query('tablet-wide', max) {
        position: relative;
        span{
         position: absolute;
         right: 1rem;
        }
       }
    }
    }
    @include media-query('phone-wide', max) {
      width: 100%;
    }
    @include media-query('tablet-wide', max) {
      width: 100%;
    }
  }
  .usersDetails{
    background-color: $Paris_M;
    max-height: 300px;
    overflow-y: auto;
    min-width: 30rem;
    padding: 1rem 2rem;
    @include font-poppins(14, $pure_white, 500, 20, normal);
    margin: 0rem 2rem;
    .responseDetailsCount{
      border-bottom: 2px solid $purple;
      padding-bottom: 0.5rem;
    }
    .selectedUserQuestions{
      padding: 0.7rem 0rem;
      .question{
        border-bottom: 1px solid $lavendar;
        .questionTitle{
          width: fit-content;
          padding: 0.7rem 0rem;
          span{
            border-bottom: 2px solid $purple;
          }
        }
        .answer{
          padding-bottom: 0.7rem;
          span{
            @include font-poppins(14, $pure_white, 300, 21, normal);
          }
        }
      }

    }

  }

  
}
}
::-webkit-scrollbar-track {
  background-color: $Paris_M;
}
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-thumb {
  background-color: $purple; 
  border-radius: 5px; 
}
.goBackButton{
  @include flex(row, initial, center);
  gap: 0.75rem;
  @include font-poppins(16, $white, 600, 24, normal);
  cursor: pointer;
}
.restrictedAccessSection{
  margin-top:1rem;
}

.customContent{
  background-color: #111236;
  .customHeader{
    background-color: #111236;
    @include font-poppins(14, $white, 600, 20, normal);
    .customClose{
      color: $white;
      border: none !important;
      &:hover{
        background-color: transparent;
      }
    }
    .selectedQuestionTitle{
      @include font-poppins(14, $white, 600, 20, normal);
      padding: 0.5rem 0.5rem;
      display: flex;
      margin-bottom: 0px;
    }
    
  }
  .customBody{
    color: $white;
    position: 'absolute';
    .selectedUserQuestions{
      display: flex;
      flex-direction: column;
      gap:1rem ;
      .question{
        background-color: $Paris_M;
        border-radius: 8px;
        padding: 0.5rem 1rem;
        .answer{
          padding-left: 2rem;
          padding-top: 0.5rem;
          @include font-poppins(14, $white, 500, 21, normal);
  
        }
        
      }
    }
    .questionDetails{
      padding:0rem 1.5rem;
      .responseDetailsCount{
        @include font-poppins(12, $purple, 400, 16, normal);
      }
      .userDetails{
        padding: 0.5rem 0;
        width: 100%;
        display: inline-grid;
        grid-template-columns: repeat(2, 1fr);
        align-self:flex-start;
        border-bottom: 1px solid $lavendar;
      }
    }
  }
}

